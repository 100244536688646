import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAdmin } from '../Redux/Reducer';


const AdminRoute = ({ children }) => {
    // const isAdmin = true;
    const isAdmin = useSelector(state => selectIsAdmin(state));

    if (isAdmin) {
        return children;
    }
    return <Navigate to='/home'></Navigate>;

};

export default AdminRoute;