import { combineReducers } from "redux";
import {
    REQUEST_GET_LIST_EVENTS,
    SUCCESS_GET_LIST_EVENTS,
    FAILURE_GET_LIST_EVENTS,
    SUCCESS_CREATE_EVENT,
    FAILURE_CREATE_EVENT,
    REQUEST_CREATE_EVENT,
    SUCCESS_DELETE_EVENT,
    FAILURE_DELETE_EVENT,
    REQUEST_DELETE_EVENT,

} from "../Constant/events";

const initialState = {
    listEventsData: {
        listEvents: [],
        error: "",
    },

    error: "",
    loading: false,

    eventsAction: {
        success: '',
        error: "",
    },
    isFetching: false,
}

const listEventsData = (state = initialState.listEventsData, action) => {
    switch (action.type) {

        case REQUEST_GET_LIST_EVENTS:
            return {
                ...state,
                listEvents: [],
                error: '',
            }
        case SUCCESS_GET_LIST_EVENTS:
            return {
                ...state,
                listEvents: action.payload ?? [],
                error: '',
            }
        case FAILURE_GET_LIST_EVENTS:
            return {
                ...state,
                listEvents: [],
                error: action.payload,
            }

        default:
            return state;
    }
};

const actionEvents = (state = initialState.eventsAction, action) => {
    switch (action.type) {

        case REQUEST_CREATE_EVENT:
        case REQUEST_DELETE_EVENT:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_CREATE_EVENT:
        case SUCCESS_DELETE_EVENT:
            return {
                ...state,
                success: action.payload.message,
                error: '',
            }
        case FAILURE_CREATE_EVENT:
        case FAILURE_DELETE_EVENT:
            return {
                ...state,
                success: '',
                error: action.payload,
            }

        default:
            return state;
    }
};

const isFetching = (state = initialState.isFetching, action) => {
    switch (action.type) {
        case REQUEST_GET_LIST_EVENTS:
        case REQUEST_CREATE_EVENT:
        case REQUEST_DELETE_EVENT:
            return true;

        case SUCCESS_GET_LIST_EVENTS:
        case SUCCESS_CREATE_EVENT:
        case SUCCESS_DELETE_EVENT:
        case FAILURE_GET_LIST_EVENTS:
        case FAILURE_CREATE_EVENT:
        case FAILURE_DELETE_EVENT:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    listEventsData,
    actionEvents,
    isFetching,
});


export const selectListEvents = state => state.listEventsData ? state.listEventsData.listEvents : [];
export const selectActionEvents = state => state.actionEvents;
