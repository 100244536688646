import React, { useEffect, useState } from 'react';
import { Box, Heading, FormControl, FormLabel, Input, Textarea, Button, VStack, Checkbox, Select, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import { commentInputIdLinkToPdfPage, commentInputPlaceholderLinkToPdfPage, dateInputIdLinkToPdfPage, dateInputPlaceholderLinkToPdfPage, sourceTypeInputPlaceholderLinkToPdfPage, submitButtonTextLinkToPdfPage, urlInputIdLinkToPdfPage, urlInputPlaceholderLinkToPdfPage, } from '../homeDynamicTexts';
import toast from 'react-hot-toast';

import Layout from '../Layout/Layout';
import Tags from './Tag';
import districtUpazilaData from '../Components/districtUpazilaData';

import { useDispatch, useSelector } from 'react-redux';
import { addLinksDataSubmit } from '../Redux/Thunk/Home';
import { selectToken, selectData, selectUserName } from '../Redux/Reducer';
import { archiveHistorySubmit } from '../Redux/Thunk/ArchiveUpload';


export default function ReportIncident() {
	const dispatch = useDispatch();

	const token = useSelector(state => selectToken(state));
	const userName = useSelector(state => selectUserName(state));
	// const profile = useSelector(state => selectProfile(state));
	const data = useSelector(state => selectData(state));

	// const { innerWidth, innerHeight } = window;

	const [date, setDate] = useState('');
	const handleDateChange = (e) => setDate(e.target.value);

	const [url, setURL] = useState('');
	const handleURLChange = (e) => setURL(e.target.value);

	// const [location, setLocation] = useState('');
	const [selectedDistrict, setSelectedDistrict] = useState('');
	const [upazilas, setUpazilas] = useState([]);
	const [selectedUpazila, setSelectedUpazila] = useState('');
	const handleDistrictChange = (event) => {
		const district = event.target.value;
		setSelectedDistrict(district);
		setUpazilas(districtUpazilaData[district] || []);
		setSelectedUpazila(''); // Reset upazila when district changes
	};
	const handleUpazilaChange = (event) => {
		const upazila = event.target.value;
		setSelectedUpazila(upazila);
	};

	const [selectedCategoriesOptions, setSelectedCategoriesOptions] = useState([]);
	const [categoriesOptions, setCategoriesOptions] = useState([
		{ value: 'সংখ্যালঘু নির্যাতন', label: 'সংখ্যালঘু নির্যাতন' },
		{ value: 'ঘরবাড়ি ও কারখানা লুটপাট(আইন শৃঙ্খলা পরিস্থিতি অবনতি)', label: 'ঘরবাড়ি ও কারখানা লুটপাট(আইন শৃঙ্খলা পরিস্থিতি অবনতি)' },
		{ value: 'সাংবাদিক নির্যাতন', label: 'সাংবাদিক নির্যাতন' },
		{ value: 'শিক্ষক নির্যাতন', label: 'শিক্ষক নির্যাতন' },
		{ value: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনী কর্তৃক অত্যাচার', label: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনী কর্তৃক অত্যাচার' },
		{ value: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনীর উপরে ঘটে যাওয়া নৃশংসতা', label: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনীর উপরে ঘটে যাওয়া নৃশংসতা' },
		{ value: 'উসকানীমূলক বক্তব্য', label: 'উসকানীমূলক বক্তব্য' },
		{ value: 'প্রকাশ্য জঙ্গীবাদী তৎপরতা', label: 'প্রকাশ্য জঙ্গীবাদী তৎপরতা' },
		{ value: 'প্রকাশ্য সাম্প্রদায়িক তৎপরতা', label: 'প্রকাশ্য সাম্প্রদায়িক তৎপরতা' },
		{ value: 'অগনতান্ত্রিক আচরন', label: 'অগনতান্ত্রিক আচরন' },
		{ value: 'বিচার বিভাগীয় ব্যর্থতা', label: 'বিচার বিভাগীয় ব্যর্থতা' },
		{ value: '১৯৭১ সাল  সম্পর্কিত যেকোন ‌অপপ্রচার অথবা হামলা', label: '১৯৭১ সাল  সম্পর্কিত যেকোন ‌অপপ্রচার অথবা হামলা' },
		{ value: 'সাংস্কৃতিক আইকন, বা মূর্তি সম্পর্কিত যেকোন হামলা', label: 'সাংস্কৃতিক আইকন, বা মূর্তি সম্পর্কিত যেকোন হামলা' },
		{ value: 'Other', label: 'Other' },
	]);
	// const [isOpen, setIsOpen] = useState(false);
	// const toggleDropdown = () => setIsOpen(!isOpen);
	const handleCheckboxChange = (value) => {
		setSelectedCategoriesOptions((prev) =>
			prev.includes(value)
				? prev.filter((item) => item !== value)
				: [...prev, value]
		);
	};

	const [selectedSourceTypeOption, setSelectedSourceTypeOption] = useState('');
	const [options, setOptions] = useState([
		{ label: 'Webpage', value: 'webpage' },
		{ label: 'Facebook Video', value: 'facebook' },
		{ label: 'Youtube Video', value: 'youtube' },
	]);
	const handleSourceTypeChange = (event) => {
		setSelectedSourceTypeOption(event.target.value);
	};

	const [comment, setComment] = useState('');
	const handleCommentChange = (e) => setComment(e.target.value);

	const [listSelectedTags, listSetSelectedTags] = useState([]);

	const [historyCreateFlag, setHistoryCreateFlag] = useState(0);

	const handleSubmit = event => {
		event.preventDefault();

		if (selectedSourceTypeOption) {
			if (selectedCategoriesOptions.length > 0) {

				dispatch(addLinksDataSubmit(date, url, selectedCategoriesOptions, selectedDistrict, selectedUpazila, selectedSourceTypeOption, comment, listSelectedTags, token));

				setHistoryCreateFlag(historyCreateFlag + 1);
			}
			else {
				toast.error('Please select of at least one category.');
			}
		}
		else {
			toast.error('Please select a Source Type.');
		}
	};

	const handleCreateHistorySubmit = () => {
		dispatch(archiveHistorySubmit(
			data.id,
			date,
			[],
			selectedCategoriesOptions,
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'link',
			url,
			selectedSourceTypeOption,
			comment,
			token,
			userName.first_name,
			userName.last_name,
			listSelectedTags,
			selectedDistrict,
			selectedUpazila,
		));

		setDate('');
		setURL('');
		setSelectedCategoriesOptions([]);
		listSetSelectedTags([]);
		setSelectedSourceTypeOption('');
		setComment('');
		setSelectedDistrict('');
		setUpazilas([]);
		setSelectedUpazila('');
		setHistoryCreateFlag(0);
	};

	useEffect(() => {
		if (data.success) {
			toast.success("This link has been documented");
		}
		if (data.error.message) {
			toast.error(data.error.message);
		}
	}, [data.error.message, data.success, dispatch]);

	useEffect(() => {
		if (data.success && data.id && historyCreateFlag === 1) {
			handleCreateHistorySubmit();
		}
	}, [data]);


	return (
		<Layout>
			<Box
				px={{ base: '2', md: '4', }}
				py={{ base: '4', md: '8' }}
				maxW={{ base: "98vw", md: "80vw" }} mx="auto"
			//minH={innerHeight * 0.756}
			>

				{/* Heading */}
				<Heading as="h1" fontSize="4xl" fontWeight="bold" color="gray.800" mb={6} fontFamily="Montserrat, sans-serif">
					Report an Incident
				</Heading>

				{/* Form Section */}
				<Box bg="white" shadow="md" rounded="lg" p={6}>
					<VStack spacing={6} as="form">
						{/* Date */}
						<FormControl id="date" >
							<FormLabel fontSize="sm" fontWeight="medium" color="gray.700" mb={1}>
								Date of Incident
							</FormLabel>

							<Input
								id={dateInputIdLinkToPdfPage}
								name={dateInputIdLinkToPdfPage}
								placeholder={dateInputPlaceholderLinkToPdfPage}
								// size='md'
								// minLength="4"
								// maxLength="8"
								type='date'
								value={date}
								onChange={handleDateChange}
							/>
						</FormControl>

						{/* URL */}
						<FormControl id="location" isRequired>
							<FormLabel fontSize="sm" fontWeight="medium" color="gray.700" mb={1}>
								URL
							</FormLabel>
							<Input
								name={urlInputIdLinkToPdfPage}
								id={urlInputIdLinkToPdfPage}
								variant='filled'
								type="url"
								placeholder={urlInputPlaceholderLinkToPdfPage}
								onChange={handleURLChange}
								required
								value={url}
							/>
						</FormControl>

						{/* Category*/}
						<FormControl id="category" isRequired>
							<FormLabel fontSize="sm" fontWeight="medium" color="gray.700" mb={1}>
								Category
							</FormLabel>
							<Box ml={5} >
								<Box border="1px solid" borderColor="gray.200" borderRadius="md" mt={2} p={2} bg="white">
									{categoriesOptions.map((option) => (
										<Box key={option.value}>
											<Checkbox
												colorScheme='red'
												isChecked={selectedCategoriesOptions.includes(option.value)}
												onChange={() => handleCheckboxChange(option.value)}
											>
												{option.label}
											</Checkbox>
										</Box>
									))}
								</Box>
							</Box>
						</FormControl>

						{/* district  */}
						<FormControl id="district">
							<FormLabel fontSize="sm" fontWeight="medium" color="gray.700" mb={1}>District</FormLabel>
							<Select
								placeholder="Select District"
								size="md"
								variant="filled"
								focusBorderColor="blue.500"
								borderRadius="md"
								boxShadow="sm"
								onChange={handleDistrictChange}
								value={selectedDistrict}
							>
								{Object.keys(districtUpazilaData)?.map((district) => (
									<option key={district} value={district}>
										{district}
									</option>
								))}
							</Select>
						</FormControl>

						{/* upazila  */}
						<FormControl id="upazila">
							<FormLabel fontSize="sm" fontWeight="medium" color="gray.700" mb={1}>Upazila</FormLabel>
							<Select
								placeholder="Select Upazila"
								size="md"
								variant="filled"
								focusBorderColor="blue.500"
								borderRadius="md"
								boxShadow="sm"
								isDisabled={!selectedDistrict}
								onChange={handleUpazilaChange}
								value={selectedUpazila}
							>
								{upazilas?.map((upazila) => (
									<option key={upazila} value={upazila}>
										{upazila}
									</option>
								))}
							</Select>
						</FormControl>

						{/* Sources */}
						<FormControl id="sources" isRequired>
							<FormLabel fontSize="sm" fontWeight="medium" color="gray.700" mb={1}>
								Sources (optional)
							</FormLabel>

							<Select
								placeholder={sourceTypeInputPlaceholderLinkToPdfPage}
								//size={["md", "md", "lg"]}
								//fontSize={{ base: 'sm', md: 'md' }}
								variant="filled"
								focusBorderColor="blue.500"
								borderRadius="md"
								//boxShadow="sm"
								//minWidth="100%"
								// required
								onChange={handleSourceTypeChange}
								value={selectedSourceTypeOption}
							>
								{options.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</Select>
						</FormControl>

						{/* comment */}
						<FormControl id="comment">
							<FormLabel fontSize="sm" fontWeight="medium" color="gray.700" mb={1}>
								Comment
							</FormLabel>
							<Textarea
								name={commentInputIdLinkToPdfPage}
								id={commentInputIdLinkToPdfPage}
								variant='filled'
								resize="vertical"  // "horizontal", "both", or "none"
								width='100%'
								rows={5}
								placeholder={commentInputPlaceholderLinkToPdfPage}
								onChange={handleCommentChange}
								value={comment}
							/>
						</FormControl>

						{/* tags */}
						<FormControl id="comment">
							<FormLabel fontSize="sm" fontWeight="medium" color="gray.700" mb={1}>
								Tags
							</FormLabel>

							<Tags
								embedded={true}
								listSelectedTags={listSelectedTags}
								listSetSelectedTags={listSetSelectedTags}
							/>
						</FormControl>

						{/* Submit Button */}
						<Button
							w="full"
							bg="red.600"
							_hover={{ bg: 'red.700' }}
							color="white"
							type='submit'
							onClick={(event) => {
								if (url) {
									handleSubmit(event);
								}
							}}
						>
							{submitButtonTextLinkToPdfPage}
						</Button>
					</VStack>
				</Box>

				{/* Why Report Section */}
				<Box align="left" mt={12}>
					<Heading as="h2" fontSize="3xl" fontWeight="semibold" color="gray.800" mb={6} fontFamily="Montserrat, sans-serif">
						Why Report?
					</Heading>
					<Box bg="white" shadow="md" rounded="lg" p={6}>
						<Text color="gray.600" mb={4}>
							Your reports are crucial in helping us monitor and analyze conflicts across Bangladesh. By submitting incident reports, you contribute to:
						</Text>
						<UnorderedList color="gray.600" spacing={2} mb={4}>
							<ListItem>Building a comprehensive database of conflict incidents</ListItem>
							<ListItem>Identifying patterns and trends in conflict dynamics</ListItem>
							<ListItem>Informing policymakers and peace activists</ListItem>
							<ListItem>Promoting awareness and understanding of local issues</ListItem>
						</UnorderedList>
						<Text color="gray.600">
							Your participation helps create a more peaceful and stable Bangladesh. Thank you for your contribution to this important work.
						</Text>
					</Box>
				</Box>
			</Box>
		</Layout>
	);
};


