import {
    REQUEST_GET_LIST_TAGS,
    SUCCESS_GET_LIST_TAGS,
    FAILURE_GET_LIST_TAGS,
    REQUEST_CREATE_TAG,
    SUCCESS_CREATE_TAG,
    FAILURE_CREATE_TAG,
    REQUEST_DELETE_TAG,
    SUCCESS_DELETE_TAG,
    FAILURE_DELETE_TAG,

    // UPDATE_SESSION_EXPIRY,
} from "../Constant/tags";


export const requestGetListTags = () => ({
    type: REQUEST_GET_LIST_TAGS,
})
export const getListTagsSuccess = (data) => ({
    type: SUCCESS_GET_LIST_TAGS,
    payload: data,
})
export const getListTagsFailure = (error) => ({
    type: FAILURE_GET_LIST_TAGS,
    payload: error,
})


export const requestCreateTag = () => ({
    type: REQUEST_CREATE_TAG,
})
export const createTagSuccess = (data) => ({
    type: SUCCESS_CREATE_TAG,
    payload: data,
})
export const createTagFailure = (error) => ({
    type: FAILURE_CREATE_TAG,
    payload: error,
})


export const requestDeleteTag = () => ({
    type: REQUEST_DELETE_TAG,
})
export const deleteTagSuccess = (data) => ({
    type: SUCCESS_DELETE_TAG,
    payload: data,
})
export const deleteTagFailure = (error) => ({
    type: FAILURE_DELETE_TAG,
    payload: error,
})