import axios from "axios";
import {
    requestSignup,
    signupSuccess,
    signupFailure,
    requestLogin,
    loginSuccess,
    loginFailure,
    // requestLogout,
    logoutSuccess,
    // logoutFailure,
    requestUpdateProfile,
    updateProfileSuccess,
    updateProfileFailure,

    requestGetUserHistory,
    getUserHistorySuccess,
    getUserHistoryFailure,

    requestUpdatePassword,
    updatePasswordSuccess,
    updatePasswordFailure,

    requestUserList,
    getUserListSuccess,
    getUserListFailure,
    requestUserTypeChange,
    getUserTypeChangeSuccess,
    getUserTypeChangeFailure,
    requestUserBan,
    userBanSuccess,
    userBanFailure,

    // updateSessionExpiry,
} from "../../ActionCreator/login";
import { login_url, logout_url, profileUpdate_url, signup_url, get_user_list_url, user_update_url, password_update_url, get_user_history_url } from "../../../allApiPath";


export const signup = (firstName, lastName, email, password, captchaValue) => async (dispatch, getState) => {
    dispatch(requestSignup())

    axios.post(signup_url, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        account_type: 'public',
        g_recaptcha_response: captchaValue,
    })
        .then((response) => {
            dispatch(signupSuccess(response.data));
        }, error => {
            dispatch(signupFailure(error));
        })
}

// export const login = (email, password, account_type) => async (dispatch, getState) => {
export const login = (email, password, account_type, captchaValue) => async (dispatch, getState) => {
    dispatch(requestLogin())

    axios.post(login_url, {
        email: email,
        password: password,
        g_recaptcha_response: captchaValue,
    })
        .then((response) => {
            dispatch(loginSuccess(response.data));
        }, error => {
            dispatch(loginFailure(error))
        })
}


export const logout = (user_id, token) => async (dispatch, getState) => {
    dispatch(logoutSuccess());
    // dispatch(requestLogout())

    // axios.post(logout_url, {
    // user_id: user_id,
    // }, {
    // headers: {
    // 'Authorization': token,
    // },
    // })
    // .then((response) => {
    // dispatch(logoutSuccess(response.data));
    // dispatch(updateSessionExpiry(nextDate));
    // }, error => {
    // dispatch(logoutFailure(error))
    // })
}


export const profileUpdate = (userName, user_email, user_phone, address, birthday, token) => async (dispatch, getState) => {
    dispatch(requestUpdateProfile())

    axios.post(profileUpdate_url, {
        userName: userName,
        user_email: user_email,
        user_phone: user_phone,
        address: address,
        birthday: birthday,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(updateProfileSuccess(response.data));
        }, error => {
            dispatch(updateProfileFailure(error))
        })
}


export const getUserHistoryData = (skip, limit, token, userID) => async (dispatch, getState) => {
    dispatch(requestGetUserHistory())
    // console.log(token, skip, limit, userID);

    // axios.get(get_user_history_url, {
    //     skip: skip,
    //     limit: limit,
    //     userID: userID,
    // }, {
    //     headers: {
    //         'Authorization': token,
    //     },
    // })
    axios.get(get_user_history_url(skip, limit, userID), {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            // console.log(response.data);
            dispatch(getUserHistorySuccess(response.data));
            // dispatch(updateSessionExpiry(nextDate));
        }, error => {
            dispatch(getUserHistoryFailure(error));
            // console.log(error);
        })
}


export const passwordUpdate = (currentPassword, newPassword, token, email, id) => async (dispatch, getState) => {
    dispatch(requestUpdatePassword())

    axios.post(password_update_url, {
        id: id,
        password: currentPassword,
        new_password: newPassword,
        email: email,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(updatePasswordSuccess(response.data));
        }, error => {
            dispatch(updatePasswordFailure(error))
        })
}

export const getUserListData = (skip, limit, token) => async (dispatch, getState) => {
    dispatch(requestUserList())

    axios.post(get_user_list_url, {
        skip: skip,
        limit: limit,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(getUserListSuccess(response.data));
        }, error => {
            dispatch(getUserListFailure(error));
        })
}


export const userTypeChange = (id, account_type, token) => async (dispatch, getState) => {
    dispatch(requestUserTypeChange())

    axios.post(user_update_url, {
        id: id,
        account_type: account_type,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(getUserTypeChangeSuccess(response.data));
        }, error => {
            dispatch(getUserTypeChangeFailure(error));
        })
}

export const userBan = (id, token) => async (dispatch, getState) => {
    dispatch(requestUserBan())
    // console.log(token, skip, limit);

    axios.post(user_update_url, {
        id: id,
        suspended: 'yes',
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            // console.log(response.data);
            dispatch(userBanSuccess(response.data));
            // dispatch(updateSessionExpiry(nextDate));
        }, error => {
            dispatch(userBanFailure(error));
            // console.log(error);
        })
}

