import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Box } from "@chakra-ui/react";

const Layout = ({ children }) => {
	return (
		<Box minH="100vh" bg="gray.50" fontFamily="Lato, sans-serif">
			<Header />
			<div>{children}</div>
			<Footer />
		</Box>
	);
};

export default Layout;