import axios from "axios";
import {
    requestGetListEvents,
    getListEventsSuccess,
    getListEventsFailure,
    requestCreateEvent,
    createEventSuccess,
    createEventFailure,
    requestDeleteEvent,
    deleteEventSuccess,
    deleteEventFailure,

} from "../../ActionCreator/events";

import { get_event_list_url, event_create_url, event_delete_url } from "../../../allApiPath";

export const getListEventsData = (skip, limit, token) => async (dispatch, getState) => {
    dispatch(requestGetListEvents())

    axios.get(get_event_list_url(skip, limit), {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(getListEventsSuccess(response.data));
        }, error => {
            dispatch(getListEventsFailure(error));
        })
}


export const createEvent = (
    name,
    token,
) => async (dispatch, getState) => {

    dispatch(requestCreateEvent())

    axios.post(event_create_url, {
        name: name,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(createEventSuccess(response.data));
        }, error => {
            dispatch(createEventFailure(error))
        })
}


export const deleteEvent = (
    event,
    token,
) => async (dispatch, getState) => {

    dispatch(requestDeleteEvent())

    axios.post(event_delete_url, {
        event: event,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(deleteEventSuccess(response.data));
        }, error => {
            dispatch(deleteEventFailure(error))
        })
}
