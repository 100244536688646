
export const REQUEST_SUBMIT_DATA = "REQUEST_SUBMIT_DATA";
export const SUCCESS_SUBMIT_DATA = "SUCCESS_SUBMIT_DATA";
export const FAILURE_SUBMIT_DATA = "FAILURE_SUBMIT_DATA";

export const REQUEST_SUBMIT_ADD_LINKS_DATA = "REQUEST_SUBMIT_ADD_LINKS_DATA";
export const SUCCESS_SUBMIT_ADD_LINKS_DATA = "SUCCESS_SUBMIT_ADD_LINKS_DATA";
export const FAILURE_SUBMIT_ADD_LINKS_DATA = "FAILURE_SUBMIT_ADD_LINKS_DATA";

// export const UPDATE_SESSION_EXPIRY = "UPDATE_SESSION_EXPIRY";
