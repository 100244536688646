import React, { forwardRef, useEffect, useRef, useState } from 'react';
import {
	Container,
	Stack,
	Box,
	Button,
	Input,
	useDisclosure,
	useMergeRefs,
	Heading,
	FormControl,
	FormLabel,
	InputGroup,
	IconButton,
	InputRightElement,
} from '@chakra-ui/react';
import { Form } from 'react-router-dom';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { selectID, selectToken, selectUpdatePassword, selectUserName } from '../Redux/Reducer';
import { passwordUpdate } from '../Redux/Thunk/Login';

import Layout from '../Layout/Layout';

const UpdatePassword = () => {
	const dispatch = useDispatch();

	const token = useSelector(state => selectToken(state));
	const id = useSelector(state => selectID(state));
	const updatePassword = useSelector(state => selectUpdatePassword(state));
	const userName = useSelector(state => selectUserName(state));

	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');

	// for password field
	const { isOpen: isCurrentOpen, onToggle: onCurrentToggle } = useDisclosure();
	const { isOpen: isNewOpen, onToggle: onNewToggle } = useDisclosure();

	const inputRef = useRef(null);
	const mergeRef = useMergeRefs(inputRef, forwardRef.ref);

	const onClickReveal = (key) => {
		if (key === 'current') {
			onCurrentToggle()
			if (inputRef.current) {
				inputRef.current.focus({
					preventScroll: true,
				})
			}
		}
		if (key === 'new') {
			onNewToggle()
			if (inputRef.current) {
				inputRef.current.focus({
					preventScroll: true,
				})
			}
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault();

		dispatch(passwordUpdate(currentPassword, newPassword, token, userName.email, id));

		setCurrentPassword('');
		setNewPassword('');
	};

	// error message show
	useEffect(() => {
		if (updatePassword.success) {
			toast.success(updatePassword.success);
		}
		if (updatePassword.error) {
			toast.error(updatePassword.error.message);
		}
	}, [updatePassword.error, updatePassword.success]);


	return (
		<Layout>
			<Container
				pb={{ base: '2', md: '4', }}
				px={{ base: '3', md: '8', }}
				maxWidth={"1400px"}
				minH={"51.8vh"}
				mx={'auto'}
			>
				< Container
					maxW="lg"
					mx={'auto'}
					py={{ base: '12', md: '12', }}
					px={{ base: '0', sm: '8', }}
				>
					<Stack
						spacing="8"
						border='2px'
						borderColor='green'
						py={4}
						bg={'white'}
						borderRadius={15}
					>
						<Heading fontSize={{ base: 'lg', md: 'xl', }} fontWeight={'bold'} textAlign="center">
							Update Your Password
						</Heading>

						<Box
							pt={{ base: '0', sm: '4', }}
							pb={{ base: '0', sm: '0', }}
							px={{ base: '4', sm: '10', }}
						>
							<Form onSubmit={handleSubmit}>
								<Stack spacing="8">
									<Stack spacing="2" >
										<FormControl isRequired>
											<FormLabel htmlFor="currentPassword" fontWeight={'bold'}>Current Password</FormLabel>

											<InputGroup>
												<InputRightElement color={'black'} p={1} >
													<IconButton
														variant="text"
														aria-label={isCurrentOpen ? 'Mask password' : 'Reveal password'}
														icon={isCurrentOpen ? <HiEyeOff /> : <HiEye />}
														onClick={() => onClickReveal('current')}
													/>
												</InputRightElement>

												<Input
													id="currentPassword"
													ref={mergeRef}
													name="currentPassword"
													type={isCurrentOpen ? 'text' : 'password'}
													// autoComplete="current-password"
													placeholder='Type your current password here'
													px={2}
													w={'90%'}
													onChange={(e) => setCurrentPassword(e.target.value)}
													value={currentPassword}
													isRequired
												/>
											</InputGroup>
										</FormControl>

										<FormControl isRequired>
											<FormLabel htmlFor="newPassword" fontWeight={'bold'}>New Password</FormLabel>

											<InputGroup>
												<InputRightElement color={'black'} p={1} >
													<IconButton
														variant="text"
														aria-label={isNewOpen ? 'Mask password' : 'Reveal password'}
														icon={isNewOpen ? <HiEyeOff /> : <HiEye />}
														onClick={() => onClickReveal("new")}
													/>
												</InputRightElement>

												<Input
													id="newPassword"
													ref={mergeRef}
													name="newPassword"
													type={isNewOpen ? 'text' : 'password'}
													placeholder='Type your new password here'
													px={2}
													w={'90%'}
													onChange={(e) => setNewPassword(e.target.value)}
													value={newPassword}
													isRequired
												/>
											</InputGroup>
										</FormControl>
									</Stack>

									<Button
										bgColor={'blue.400'}
										color={'white'}
										_hover={{ bg: "blue.500", color: "white" }}
										p={1}
										borderRadius={'lg'}
										type='submit'
									>
										Update
									</Button>
								</Stack>
							</Form>
						</Box>
					</Stack >
				</Container >
			</Container>
		</Layout>
	);
};

export default UpdatePassword;
