import React from 'react';
import { Box, Heading, Text, Button, Flex, VStack, HStack, Icon, Select, Link, Checkbox, Tooltip, } from '@chakra-ui/react';
import { FaHistory, FaRegEdit } from "react-icons/fa";
import { BookOpen } from 'react-feather';
import { DownloadIcon } from "@chakra-ui/icons";



export default function List({
	filteredData,
	decode,
	handleDownload,
	handlePreview,
	selectFlag,
	selectedFile,
	setSelectFile,
	handleEdit,
	handleEditHistory,
}) {

	return (
		<Box>
			<Heading
				as="h2"
				fontSize="2xl"
				fontWeight="semibold"
				color="gray.800"
				mb={4}
				fontFamily="Montserrat, sans-serif"
			>
				Recent Uploads
			</Heading>
			<Box>
				{/*{[1, 2, 3].map((i) => (
                <Box key={i} bg="white" shadow="md" rounded="lg" p={4} mb={4}>
                    <Flex backgroundColor='white'justifyContent='flex-start'>
                    <Icon as={BookOpen} color="red.600" mr={4} mt={1} />
                    <Box textAlign='left'>
                        <Heading as="h3" fontSize="lg" fontWeight="semibold" mb={2}>
                        Featured Resource {i}
                        </Heading>
                        <Text color="gray.600" fontSize="sm" mb={2}>
                        Author: John Doe
                        </Text>
                        <Text color="gray.600" fontSize="sm" mb={4}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </Text>
                        <Button variant="outline" size="sm" colorScheme="red">
                        Read More
                        </Button>
                    </Box>
                    </Flex>
                </Box>
                ))}*/}
				{filteredData.length > 0 ? (
					filteredData.map((td, i) => (
						<Box key={i} bg="white" shadow="md" rounded="lg" p={4} mb={4}>
							<Flex
								mx="auto"
								direction={{ base: 'column', md: 'row' }}
								justify={{ base: 'center', md: 'space-between' }}
								//direction={['column', 'column', 'column']}
								//margin={3}
								//padding={2}
								//borderWidth={1}
								//borderRadius="md"
								//borderColor="black"
								key={td.id}

								backgroundColor='white'
							>
								{/*<Text
									w={["100%", "100%", "5%"]}
									fontSize={['xs', 'sm', 'md']}
									fontWeight="bold"
									color="black"
									textAlign="center"
									alignSelf="center"
								>
									{i + 1 + (currentPage - 1) * itemsPerPage}
								</Text>*/}
								<HStack w={{ base: "100%", md: "60%" }} align={'flex-start'} mr={2}>
									<Icon as={BookOpen} color="red.600" mt={1} />

									<VStack
										align="flex-start"
										gap={1}
										w={["100%", "100%", "100%"]}
									>
										<Text
											//fontWeight="semibold"
											wordBreak="break-word"
											whiteSpace="normal"
											color="gray.600"
											fontSize="sm"
											mb={{ base: 1, md: 2 }}
										>
											Category: {Array.isArray(td?.categories) && td?.categories?.join(', ')}
										</Text>
										<Text
											//fontWeight="semibold"
											wordBreak="break-word"
											whiteSpace="normal"
											color="gray.600"
											fontSize="sm"
											mb={{ base: 1, md: 2 }}
										>
											Source: {td?.description}
										</Text>
										<Text
											//fontWeight="semibold"
											wordBreak="break-word"
											whiteSpace="normal"
											color="gray.600" fontSize="sm" mb={2}
											textOverflow='inherit'

										>
											URL:
											<Link
												//fontWeight="semibold"
												wordBreak="break-word"
												whiteSpace="normal"
												href={td.source_url}
												color="teal.600"
												fontSize="sm"
												mb={{ base: 1, md: 2 }}
												isExternal
											>
												{decode(td?.source_url)} {/* Display the decoded URL */}
											</Link>
										</Text>
									</VStack>
								</HStack>

								<Box m={0} pl={1} display="flex" flexDirection={["column", "row", "column", "row"]} alignItems={{ base: "center", lg: "flex-end" }} justifyContent="center">
									<HStack>
										<Button
											isDisabled={"waiting" === td.status ? true : false}
											//bgColor="blue.500"
											//color="white"
											//_hover={{ bg: "blue.400", color: "white" }}
											mb={3}
											onClick={() => handleDownload(td.file_names)}
											fontSize={{ base: "sm", md: "md" }}
											size={{ base: "sm", md: "md" }}
											mx={1}
											variant="outline"
											leftIcon={<DownloadIcon />}
											colorScheme="red"
										>
											Download
										</Button>

										<Select
											w="45%"
											placeholder="Select Preview"
											size={{ base: "sm", md: "md" }}
											// variant="filled"
											fontSize={{ base: "xs", md: "sm" }}
											_placeholder={{
												color: "gray.800", // Responsive color for the placeholder text
												fontSize: ["sm", "md", "lg"], // Adjust font size for mobile, tablet, desktop
											}}

											focusBorderColor="gray.900"
											borderRadius="md"
											boxShadow="sm"
											borderColor={"gray.900"}
											mx={1}
											mb={3}
											onChange={(e) => {
												handlePreview(e.target.value, td);
												e.target.value = "";
											}}
										// value={''}
										>
											{td?.file_names?.map((fileName, i) => (
												<option key={fileName} value={fileName} >
													File No. {i + 1}
												</option>
											))}
										</Select>
									</HStack>

									<HStack >
										<Tooltip hasArrow label='Edit' bg='gray.300' color='black' placement='top'>
											<Button
												bgColor="white"
												color="gray.800"
												_hover={{ bg: "gray.900", color: "white" }}
												mb={3}
												mx={1}
												onClick={() => handleEdit(td)}
												pr={'.5rem'}
											>
												<FaRegEdit size="25" />
											</Button>
										</Tooltip>

										<Tooltip hasArrow label='Edit History' bg='gray.300' color='black' placement='top'>
											<Button
												bgColor="white"
												color="gray.800"
												_hover={{ bg: "gray.900", color: "white" }}
												mb={3}
												mx={1}
												onClick={() => handleEditHistory(td.id)}
												pr={'.5rem'}
											>
												<FaHistory size="25" />
											</Button>
										</Tooltip>

										{selectFlag &&
											<Checkbox
												mb={3}
												isChecked={selectedFile.includes(td.file_names[0])}
												onChange={() => {
													td?.file_names?.map((filename, i) => (
														setSelectFile((prev) =>
															prev.includes(filename)
																? prev.filter((item) => item !== filename)
																: [...prev, filename]
														)
													))
												}}
											/>
										}
									</HStack>

								</Box>
								{/*</VStack>*/}
							</Flex>
						</Box>
					))
				) : (
					<Text textAlign="center" my={4}>
						No data found for the current search/filter.
					</Text>
				)}
			</Box>
		</Box>
	)
};

