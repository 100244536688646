import { combineReducers } from "redux";

import {
    REQUEST_USER_LIST,
    SUCCESS_UPDATE_USER_LIST,
    FAILURE_UPDATE_USER_LIST,
} from "../Constant/login";

const initialState = {
    listUser: [],
    error: '',
}

const listUserData = (state = initialState, action) => {
    switch (action.type) {

        case REQUEST_USER_LIST:
            return {
                ...state,
                listUser: [],
                error: '',
            }
        case SUCCESS_UPDATE_USER_LIST:
            return {
                ...state,
                listUser: action.payload,
                error: '',
            }
        case FAILURE_UPDATE_USER_LIST:
            return {
                ...state,
                listUser: [],
                error: action.payload,
            }

        default:
            return state;
    }
};

export default combineReducers({
    listUserData,
});

export const selectUserList = state => state.listUserData || [];
export const selectUpdateMessage = state => state.updateMessageData;