
export const REQUEST_SUBMIT_ARCHIVE_DATA = "REQUEST_SUBMIT_ARCHIVE_DATA";
export const SUCCESS_SUBMIT_ARCHIVE_DATA = "SUCCESS_SUBMIT_ARCHIVE_DATA";
export const FAILURE_SUBMIT_ARCHIVE_DATA = "FAILURE_SUBMIT_ARCHIVE_DATA";

export const REQUEST_SUBMIT_ARCHIVE_HISTORY = "REQUEST_SUBMIT_ARCHIVE_HISTORY";
export const SUCCESS_SUBMIT_ARCHIVE_HISTORY = "SUCCESS_SUBMIT_ARCHIVE_HISTORY";
export const FAILURE_SUBMIT_ARCHIVE_HISTORY = "FAILURE_SUBMIT_ARCHIVE_HISTORY";

export const REQUEST_GET_LIST_ARCHIVE = "REQUEST_GET_LIST_ARCHIVE";
export const SUCCESS_GET_LIST_ARCHIVE = "SUCCESS_GET_LIST_ARCHIVE";
export const FAILURE_GET_LIST_ARCHIVE = "FAILURE_GET_LIST_ARCHIVE";

export const REQUEST_UPDATE_ARCHIVE = "REQUEST_UPDATE_ARCHIVE";
export const SUCCESS_UPDATE_ARCHIVE = "SUCCESS_UPDATE_ARCHIVE";
export const FAILURE_UPDATE_ARCHIVE = "FAILURE_UPDATE_ARCHIVE";

export const REQUEST_UPDATE_EDIT_HISTORY_ARCHIVE = "REQUEST_UPDATE_EDIT_HISTORY_ARCHIVE";
export const SUCCESS_UPDATE_EDIT_HISTORY_ARCHIVE = "SUCCESS_UPDATE_EDIT_HISTORY_ARCHIVE";
export const FAILURE_UPDATE_EDIT_HISTORY_ARCHIVE = "FAILURE_UPDATE_EDIT_HISTORY_ARCHIVE";

export const REQUEST_FILE_DOWNLOAD_ARCHIVE = "REQUEST_FILE_DOWNLOAD_ARCHIVE";
export const SUCCESS_FILE_DOWNLOAD_ARCHIVE = "SUCCESS_FILE_DOWNLOAD_ARCHIVE";
export const FAILURE_FILE_DOWNLOAD_ARCHIVE = "FAILURE_FILE_DOWNLOAD_ARCHIVE";

export const REQUEST_GET_ARCHIVE_EDIT_HISTORY = "REQUEST_GET_ARCHIVE_EDIT_HISTORY";
export const SUCCESS_GET_ARCHIVE_EDIT_HISTORY = "SUCCESS_GET_ARCHIVE_EDIT_HISTORY";
export const FAILURE_GET_ARCHIVE_EDIT_HISTORY = "FAILURE_GET_ARCHIVE_EDIT_HISTORY";

export const REQUEST_GET_ARCHIVE_SEARCH_RESULT = "REQUEST_GET_ARCHIVE_SEARCH_RESULT";
export const SUCCESS_GET_ARCHIVE_SEARCH_RESULT = "SUCCESS_GET_ARCHIVE_SEARCH_RESULT";
export const FAILURE_GET_ARCHIVE_SEARCH_RESULT = "FAILURE_GET_ARCHIVE_SEARCH_RESULT";


// export const UPDATE_SESSION_EXPIRY = "UPDATE_SESSION_EXPIRY";
