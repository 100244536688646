import React, { useEffect, useState } from 'react';
import { Link, Box, Flex, Text, Button, Stack, Menu, MenuButton, MenuList, MenuDivider, MenuItem, Image, MenuGroup } from "@chakra-ui/react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { TiThMenuOutline } from "react-icons/ti";

import { useDispatch, useSelector } from 'react-redux';
import { logout, } from '../Redux/Thunk/Login';
import { selectIsAdmin, selectToken, selectID, selectIsSuperAdmin, selectExpiryTime } from '../Redux/Reducer';


const Navbar = () => {
	const dispatch = useDispatch();

	const isAdmin = useSelector(state => selectIsAdmin(state));
	const isSuperAdmin = useSelector(state => selectIsSuperAdmin(state));

	const token = useSelector(state => selectToken(state));
	const user_id = useSelector(state => selectID(state));

	//This useEffect will log the user out, once the exit time arrives. The exit time was set at login. 
	const expiry_time = useSelector(state => selectExpiryTime(state));
	useEffect(() => {
		// const timeOutID = setTimeout(() => {
		const intervalID = setInterval(() => {
			const curTime = new Date().getTime();
			if (expiry_time === 0 || curTime > expiry_time) {
				dispatch(logout(user_id, token));
			}

		}, 20000);

		// return () => { clearTimeout(timeOutID) };
		return () => clearInterval(intervalID);
	});

	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	const handleLogOut = () => {
		dispatch(logout(user_id, token));
	};

	return (
		<Box bg='gray'>
			<Flex
				as="nav"
				align="center"
				justify="space-between"
				wrap="wrap"
				w="100%"
				mb={3}
				px={5}
				py='3'
				maxWidth={"80%"}
				m="0 auto"
			>
				{/* <Link href='/' w={['30%', "25%", '20%', '15%']}> */}
				<Image
					src="/assets/BangladeshConflctWatch.png"
					alt="Logo"
					w={['30%', "25%", '20%', '15%']}
				/>
				{/* </Link> */}

				<Box
					display={{ base: "block", md: "none" }}
					onClick={toggle}
				>
					{
						isOpen ?
							<IoMdCloseCircleOutline size="35" color='white' title='Close' />
							:
							<TiThMenuOutline size="27" color='white' title='Menu' />
					}
				</Box>

				<Box
					display={{ base: isOpen ? "block" : "none", md: "block" }}
					flexBasis={{ base: "100%", md: "auto" }}
				>
					<Stack
						spacing={[2, 4, 5]}
						align="center"
						pt={[4, 4, 0, 0]}
						justify={["center", "space-between", "flex-end", "flex-end"]}
						direction={["column", "row", "row", "row"]}
					>
						{/* if logged in  */}
						<Link href='/home'>
							<Text display="block" color='white'>Home</Text>
						</Link>

						{
							isSuperAdmin &&
							<Link href='/user_list'>
								<Text display="block" color='white'>Users</Text>
							</Link>
						}

						{
							isAdmin &&
							<Link href='/tag'>
								<Text display="block" color='white'>Tags</Text>
							</Link>
						}

						<Link href='/report'>
							<Text display="block" color='white'>Report</Text>
						</Link>

						<Link href='/library'>
							<Text display="block" color='white'>Library</Text>
						</Link>

						<Link href='/archive_upload'>
							<Text display="block" color='white'>Archive Upload</Text>
						</Link>

						<Link href='/archive_list'>
							<Text display="block" color='white'>Archive</Text>
						</Link>

						<Menu>
							<MenuButton as={Button}
								size="sm"
								fontWeight='bold'
								rounded="md"
								color='white'
								p='1'
								bg="green"
								_hover={{ bg: "green.400", color: "white" }}
							>
								Profile
							</MenuButton>

							<MenuList bg={'gray'}>
								{/* <MenuGroup title='Profile' color='white'> */}
								<MenuGroup>
									<MenuItem px={3} py={1} bg='gray'>
										<Link href='/profile'>
											<Text display="block" color='white' >User Profile</Text>
										</Link>
									</MenuItem>

									{/* <MenuDivider /> */}

									<MenuItem px={3} py={1} bg='gray'>
										<Link href='/update_password'>
											<Text display="block" color='white' >Update Password</Text>
										</Link>
									</MenuItem>
								</MenuGroup>

							</MenuList>
						</Menu>

						{/* if logged in  */}
						<Link onClick={handleLogOut}>
							<Button
								size="sm"
								fontWeight='bold'
								rounded="md"
								color='green'
								p='1'
								bg="white"
								_hover={{ bg: "green", color: "white" }}
							>
								Log Out
							</Button>
						</Link>
					</Stack>
				</Box>
			</Flex>
		</Box>
	);
};

export default Navbar;