import axios from "axios";
import {
    requestSubmitArchiveData,
    submitArchiveDataSuccess,
    submitArchiveDataFailure,
    requestSubmitArchiveHistory,
    submitArchiveHistorySuccess,
    submitArchiveHistoryFailure,
    requestGetListArchive,
    getListArchiveSuccess,
    getListArchiveFailure,
    requestUpdateArchive,
    updateArchiveSuccess,
    updateArchiveFailure,
    requestUpdateEditHistoryArchive,
    updateEditHistoryArchiveSuccess,
    updateEditHistoryArchiveFailure,
    requestFileDownloadArchive,
    fileDownloadArchiveSuccess,
    fileDownloadArchiveFailure,
    requestGetArchiveEditHistory,
    getArchiveEditHistorySuccess,
    getArchiveEditHistoryFailure,
    requestGetArchiveSearchResult,
    getArchiveSearchResultSuccess,
    getArchiveSearchResultFailure,
} from "../../ActionCreator/archive";

import { archive_update_edit_history_url, archive_update_url, archive_upload_url, file_download_archive_url, get_archive_edit_history_url, get_list_archive_url, get_search_list_archive_url, } from "../../../allApiPath";


export const archiveDataSubmit = (formData, token) => async (dispatch, getState) => {
    dispatch(requestSubmitArchiveData())

    axios.post(archive_upload_url, formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': token,
            },
            // body: JSON.stringify(formData),
        }
    )
        .then((response) => {
            console.log(response.data);
            dispatch(submitArchiveDataSuccess(response.data));
        }, error => {
            dispatch(submitArchiveDataFailure(error))
        })
}


export const archiveHistorySubmit = (
    id,
    date,
    selectedAttackPlaceCategoriesOptions,
    selectedCategoriesOptions,
    shortDescription,
    description,
    name,
    victim,
    address,
    occupation,
    email,
    victimConditionOptionValue,
    dataType,
    url,
    selectedSourceTypeOption,
    comment,
    token,
    first_name,
    last_name,
    listSelectedTags,
    selectedDistrict,
    selectedUpazila,
) => async (dispatch, getState) => {
    dispatch(requestSubmitArchiveHistory())

    axios.post(archive_update_edit_history_url, {
        data_type_id: id,
        review_date: date,
        attack_places: selectedAttackPlaceCategoriesOptions,
        categories: selectedCategoriesOptions,
        short_description: shortDescription,
        description: description,
        contributor_name: name,
        victim_name: victim,
        victim_address: address,
        victim_occupation: occupation,
        email: email,
        victim_condition: victimConditionOptionValue,
        data_type: dataType,
        source_url: url,
        source_type: selectedSourceTypeOption,
        comment: comment,
        first_name: first_name,
        last_name: last_name,
        tags: listSelectedTags,
        location: selectedDistrict,
        upazila: selectedUpazila,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            console.log(response.data);
            dispatch(submitArchiveHistorySuccess(response.data));
        }, error => {
            dispatch(submitArchiveHistoryFailure(error))
        })
}


export const getListArchiveData = (skip, limit, token, processed) => async (dispatch, getState) => {
    dispatch(requestGetListArchive())
    // console.log(token, skip, limit);

    axios.get(get_list_archive_url(skip, limit, processed), {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            // console.log(response.data);
            dispatch(getListArchiveSuccess(response.data));
            // dispatch(updateSessionExpiry(nextDate));
        }, error => {
            dispatch(getListArchiveFailure(error));
            // console.log(error);
        })
}


export const downloadArchiveFile = (filename, token) => async (dispatch) => {
    dispatch(requestFileDownloadArchive())

    try {
        const response = await axios.post(file_download_archive_url, {
            filename: filename,
        }, {
            responseType: 'blob', // Important for file download
            headers: {
                // Authorization: `Bearer ${token}`,
                'Authorization': token,
            }
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        const fileName = filename.split('/').pop(); // Extract file name from the full path
        link.setAttribute('download', fileName); // Set the downloaded file's name

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        dispatch(fileDownloadArchiveSuccess("Download Success"));
    } catch (error) {
        console.error('Download error:', error);
        dispatch(fileDownloadArchiveFailure(error));
    }
};


export const archiveUpdateSubmit = (
    id,
    date,
    selectedDistrict,
    selectedUpazila,
    url,
    selectedCategoriesOptions,
    selectedSourceTypeOption,
    contributorName,
    comment,
    death,
    injury,
    propertyValue,
    noOfHouses,
    propertyVandalized,
    selectedReviewStatusOption,
    token,
    listSelectedTags,
    listSelectedEvents,
) => async (dispatch, getState) => {
    dispatch(requestUpdateArchive())

    axios.post(archive_update_url, {
        evidence_id: id,
        review_date: date,
        place: selectedDistrict,
        upazilla: selectedUpazila,
        source_url: url,
        categories: selectedCategoriesOptions,
        source_type: selectedSourceTypeOption,
        contributor_name: contributorName,
        comment: comment,
        death: parseInt(death),
        injury: parseInt(injury),
        property_value: propertyValue,
        number_of_houses: parseInt(noOfHouses),
        property_vandalized: parseInt(propertyVandalized),
        review_status: selectedReviewStatusOption,
        tags: listSelectedTags,
        events: listSelectedEvents,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(updateArchiveSuccess(response.data));
        }, error => {
            dispatch(updateArchiveFailure(error))
        })
}


export const archiveUpdateEditHistorySubmit = (
    id,
    date,
    selectedDistrict,
    selectedUpazila,
    url,
    selectedCategoriesOptions,
    selectedSourceTypeOption,
    contributorName,
    comment,
    death,
    injury,
    propertyValue,
    noOfHouses,
    propertyVandalized,
    selectedReviewStatusOption,
    token,
    listSelectedTags,
    listSelectedEvents,
    dataType,
    first_name,
    last_name,
) => async (dispatch, getState) => {
    dispatch(requestUpdateEditHistoryArchive())

    axios.post(archive_update_edit_history_url, {
        data_type_id: id,
        review_date: date,
        location: selectedDistrict,
        upazilla: selectedUpazila,
        source_url: url,
        categories: selectedCategoriesOptions,
        source_type: selectedSourceTypeOption,
        contributor_name: contributorName,
        comment: comment,
        death: parseInt(death),
        injury: parseInt(injury),
        property_value: propertyValue,
        number_of_houses: parseInt(noOfHouses),
        property_vandalized: parseInt(propertyVandalized),
        review_status: selectedReviewStatusOption,
        tags: listSelectedTags,
        events: listSelectedEvents,
        data_type: dataType,
        first_name: first_name,
        last_name: last_name,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(updateEditHistoryArchiveSuccess(response.data));
        }, error => {
            dispatch(updateEditHistoryArchiveFailure(error))
        })
}


export const getArchiveEditHistoryData = (skip, limit, token, id) => async (dispatch, getState) => {
    dispatch(requestGetArchiveEditHistory())

    axios.get(get_archive_edit_history_url(skip, limit, id), {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(getArchiveEditHistorySuccess(response.data));
        }, error => {
            dispatch(getArchiveEditHistoryFailure(error));
        })
}


export const getArchiveSearchResult = (skip, limit, token, selectedSearch, selectedSearchOptions, listSearchSelectedTags, listSearchSelectedEvents) => async (dispatch, getState) => {
    dispatch(requestGetArchiveSearchResult())

    axios.post(get_search_list_archive_url, {
        skip: skip,
        limit: limit,
        source_type: selectedSearch,
        categories: selectedSearchOptions,
        // start_date: startDate,
        // end_date: endDate,
        tags: listSearchSelectedTags,
        events: listSearchSelectedEvents,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(getArchiveSearchResultSuccess(response.data));
            // console.log(response.data);
        }, error => {
            dispatch(getArchiveSearchResultFailure(error));
        })
}
