export const REQUEST_GET_LIST_EVENTS = "REQUEST_GET_LIST_EVENTS";
export const SUCCESS_GET_LIST_EVENTS = "SUCCESS_GET_LIST_EVENTS";
export const FAILURE_GET_LIST_EVENTS = "FAILURE_GET_LIST_EVENTS";

export const REQUEST_CREATE_EVENT = "REQUEST_CREATE_EVENT";
export const SUCCESS_CREATE_EVENT = "SUCCESS_CREATE_EVENT";
export const FAILURE_CREATE_EVENT = "FAILURE_CREATE_EVENT";

export const REQUEST_DELETE_EVENT = "REQUEST_DELETE_EVENT";
export const SUCCESS_DELETE_EVENT = "SUCCESS_DELETE_EVENT";
export const FAILURE_DELETE_EVENT = "FAILURE_DELETE_EVENT";