import React from "react";
import { Button } from "@chakra-ui/react";

const SearchPageButton = ({buttonLabel, onClickFunction, onClickParam}) => {
    return (
        <Button
            px={[1, 5]}
            borderRadius="lg"
            bgColor="gray.800"
            color="white"
            _hover={{ bg: "gray.900", color: "white" }}
            fontSize={["xs", "sm", "md"]}
            onClick={() => {
                onClickFunction(onClickParam);
            }}
        >
            {buttonLabel}
        </Button>
    )
}

export default SearchPageButton