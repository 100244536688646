import { combineReducers } from "redux";
import {
    REQUEST_SUBMIT_DATA,
    SUCCESS_SUBMIT_DATA,
    FAILURE_SUBMIT_DATA,
    REQUEST_SUBMIT_ADD_LINKS_DATA,
    SUCCESS_SUBMIT_ADD_LINKS_DATA,
    FAILURE_SUBMIT_ADD_LINKS_DATA,
} from "../Constant/home";

const initialState = {
    data: {
        success: '',
        id: '',
        error: "",
    },
    isFetching: false,
}

const data = (state = initialState.data, action) => {
    switch (action.type) {

        case REQUEST_SUBMIT_DATA:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_SUBMIT_DATA:
            return {
                ...state,
                success: action.payload.message,
                error: '',
            }
        case FAILURE_SUBMIT_DATA:
            return {
                ...state,
                success: '',
                error: action.payload,
            }


        case REQUEST_SUBMIT_ADD_LINKS_DATA:
            return {
                ...state,
                success: '',
                id: '',
                error: '',
            }
        case SUCCESS_SUBMIT_ADD_LINKS_DATA:
            return {
                ...state,
                success: action.payload.message,
                id: action.payload.id,
                error: '',
            }
        case FAILURE_SUBMIT_ADD_LINKS_DATA:
            return {
                ...state,
                success: '',
                id: '',
                error: action.payload,
            }

        default:
            return state;
    }
};

const isFetching = (state = initialState.isFetching, action) => {
    switch (action.type) {
        case REQUEST_SUBMIT_DATA:
        case REQUEST_SUBMIT_ADD_LINKS_DATA:
            return true;

        case SUCCESS_SUBMIT_DATA:
        case SUCCESS_SUBMIT_ADD_LINKS_DATA:
        case FAILURE_SUBMIT_DATA:
        case FAILURE_SUBMIT_ADD_LINKS_DATA:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    data,
    isFetching,
});

export const selectData = state => state.data;



// export const selectLine = state => state.lines;
// export const selectNewLine = state => state.lines;
// export const selectTranslatedLine = state => state.lines;
