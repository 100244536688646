import React, { } from "react"
import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalContent,
    ModalBody,
    ModalFooter,
    Button,
    VStack,
    Text,
    Stack,
} from "@chakra-ui/react";


const HistoryModal = (
    {
        isUserHistoryOpen,
        onUserHistoryClose,
        history,
    }
) => {
    // console.log(history);

    return (
        <Modal
            isOpen={isUserHistoryOpen}
            onClose={() => {
                onUserHistoryClose();
            }}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>User History Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack alignItems="flex-start">
                        {/* history data show  */}
                        {
                            history ?
                                <VStack alignItems="flex-start">
                                    {history.review_date &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Review Date: </Text>
                                            {history.review_date}
                                        </Text>}

                                    {history.data_type &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Data Type: </Text>
                                            {history.data_type}
                                        </Text>}

                                    {history.source_type &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Source Type Description: </Text>
                                            {history.source_type}
                                        </Text>}

                                    {history.source_url &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Source Url: </Text>
                                            {history.source_url}
                                        </Text>}

                                    {history.review_status &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Review Status: </Text>
                                            {history.review_status}
                                        </Text>}

                                    {history.name &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Victim Name: </Text>
                                            {history.name}
                                        </Text>}

                                    {history.categories &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Categories: </Text>
                                            {Array.isArray(history?.categories) && history?.categories?.join(', ')}
                                        </Text>}

                                    {history.tags &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Tags: </Text>
                                            {Array.isArray(history?.tags) && history?.tags?.join(', ')}
                                        </Text>}

                                    {history.events &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Events: </Text>
                                            {Array.isArray(history?.events) && history?.events?.join(', ')}
                                        </Text>}

                                    {history.location &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Location: </Text>
                                            {history.location}
                                        </Text>}

                                    {history.short_description &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Short Description: </Text>
                                            {history.short_description}
                                        </Text>}

                                    {history.description &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Description: </Text>
                                            {history.description}
                                        </Text>}

                                    {history.comment &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Comment: </Text>
                                            {history.comment}
                                        </Text>}

                                    {history.death &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Death: </Text>
                                            {history.death}
                                        </Text>}

                                    {history.injury &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Injury: </Text>
                                            {history.injury}
                                        </Text>}

                                    {history.number_of_houses &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Number Of Houses: </Text>
                                            {history.number_of_houses}
                                        </Text>}

                                    {history.property_value &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Property Value: </Text>
                                            {history.property_value}
                                        </Text>}

                                    {history.property_vandalized &&
                                        <Text wordBreak="break-word" whiteSpace="normal">
                                            <Text as="span" color={'blue.400'} fontWeight={'900'}>Property Vandalized: </Text>
                                            {history.property_vandalized}
                                        </Text>}

                                </VStack>
                                :
                                <Text color={'blue.400'} fontWeight={'900'}>No Data </Text>
                        }
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    {/* {
                        (flag || compareFlag) &&
                        <Button colorScheme="blue" mr={3}
                            onClick={() => {
                                setFlag(false);
                                setCompareFlag(false);
                            }}
                        >
                            Back
                        </Button>
                    } */}
                    <Button variant="ghost"
                        onClick={() => {
                            onUserHistoryClose();
                        }}
                    >Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

    )
}

export default HistoryModal;