import React, { useEffect } from 'react';
import { Button, Input, Checkbox, Select, Textarea, Box, Heading, FormControl, FormLabel, Flex, VStack, Text, Grid, GridItem } from '@chakra-ui/react';
import { AttachmentIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { UnorderedList, ListItem } from '@chakra-ui/react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import Layout from '../Layout/Layout.js'

import {
	sourceTypeInputPlaceholderLinkToPdfPage,
	submitButtonText,
	submitWaitingText,
	urlInputIdLinkToPdfPage,
	urlInputPlaceholderLinkToPdfPage,
} from '../homeDynamicTexts';
import { selectToken, selectDataArchive, selectUserName } from '../Redux/Reducer';
import { archiveDataSubmit, archiveHistorySubmit } from '../Redux/Thunk/ArchiveUpload';

export default function ArchiveUploadPage() {
	const dispatch = useDispatch();

	const token = useSelector(state => selectToken(state));
	const userName = useSelector(state => selectUserName(state));
	const data = useSelector(state => selectDataArchive(state));

	const [url, setURL] = useState('');
	const handleURLChange = (e) => setURL(e.target.value);

	const [selectedCategoriesOptions, setSelectedCategoriesOptions] = useState([]);
	const [description, setDescription] = useState("");
	const [date, setDate] = useState("");

	const [categoriesOptions, setCategoriesOptions] = useState([
		{ value: 'হিন্দু নির্যাতন', label: 'হিন্দু নির্যাতন' },
		{ value: 'ঘরবাড়ি ও কারখানা লুটপাট(আইন শৃঙ্খলা পরিস্থিতি অবনতি)', label: 'ঘরবাড়ি ও কারখানা লুটপাট(আইন শৃঙ্খলা পরিস্থিতি অবনতি)' },
		{ value: 'সাংবাদিক নির্যাতন', label: 'সাংবাদিক নির্যাতন' },
		{ value: 'শিক্ষক নির্যাতন', label: 'শিক্ষক নির্যাতন' },
		{ value: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনী কর্তৃক অত্যাচার', label: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনী কর্তৃক অত্যাচার' },
		{ value: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনীর উপরে ঘটে যাওয়া নৃশংসতা', label: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনীর উপরে ঘটে যাওয়া নৃশংসতা' },
		{ value: 'উসকানীমূলক বক্তব্য', label: 'উসকানীমূলক বক্তব্য' },
		{ value: 'প্রকাশ্য জঙ্গীবাদী তৎপরতা', label: 'প্রকাশ্য জঙ্গীবাদী তৎপরতা' },
		{ value: 'প্রকাশ্য সাম্প্রদায়িক তৎপরতা', label: 'প্রকাশ্য সাম্প্রদায়িক তৎপরতা' },
		{ value: 'অগনতান্ত্রিক আচরন', label: 'অগনতান্ত্রিক আচরন' },
		{ value: 'বিচার বিভাগীয় ব্যর্থতা', label: 'বিচার বিভাগীয় ব্যর্থতা' },
		{ value: '১৯৭১ সাল  সম্পর্কিত যেকোন ‌অপপ্রচার অথবা হামলা', label: '১৯৭১ সাল  সম্পর্কিত যেকোন ‌অপপ্রচার অথবা হামলা' },
		{ value: 'সাংস্কৃতিক আইকন, বা মূর্তি সম্পর্কিত যেকোন হামলা', label: 'সাংস্কৃতিক আইকন, বা মূর্তি সম্পর্কিত যেকোন হামলা' },
		{ value: 'Other', label: 'Other' },
	]);

	const handleCheckboxChange = (value) => {
		setSelectedCategoriesOptions((prev) =>
			prev.includes(value)
				? prev.filter((item) => item !== value)
				: [...prev, value]
		);
	};

	const [selectedSourceTypeOption, setSelectedSourceTypeOption] = useState('');
	const [options, setOptions] = useState([
		{ value: 'Newspaper', label: 'Newspaper' },
		{ value: 'Youtube', label: 'YouTube' },
		{ value: 'Facebook', label: 'Facebook' },
	]);
	const handleSourceTypeChange = (event) => {
		setSelectedSourceTypeOption(event.target.value);
	};

	const [uploadFlag, setUploadFlag] = useState(false);

	// file upload 
	// for multiple file system
	const [selectedFiles, setSelectedFiles] = useState(null);
	const [previews, setPreviews] = useState([]);
	const [error, setError] = useState('');
	useEffect(() => {
		// Cleanup the object URLs when component unmounts or previews change
		return () => {
			previews.forEach(file => URL.revokeObjectURL(file.url));
		};
	}, [previews]);
	const handleFileChange = (event) => {
		const files = Array.from(event.target.files);

		const maxSize = 2 * 1024 * 1024 * 1024; // 2GB in bytes

		const validFiles = [];
		const filePreviews = [];
		files.forEach(file => {
			if (file.size > maxSize) {
				setError(`File "${file.name}" exceeds the 2GB size limit.`);
			} else {
				validFiles.push(file);
				const fileURL = URL.createObjectURL(file);
				filePreviews.push({ name: file.name, type: file.type, url: fileURL });
			}
		});
		if (validFiles.length > 0) {
			setSelectedFiles(validFiles);
			setPreviews(filePreviews);
			setError(''); // Clear any previous errors
		}
	};


	const handleSubmit = event => {
		event.preventDefault();

		// if (selectedCategoriesOptions.length > 0) {
		if (selectedFiles) {     // for multiple file system

			const formData = new FormData();

			formData.append('source_url', url);
			selectedCategoriesOptions.forEach((opt) => {
				formData.append('categories', opt);
			});
			formData.append('source_description', selectedSourceTypeOption);
			formData.append('description', description);
			formData.append('date', date);
			// for multiple file system
			selectedFiles.forEach((file) => {
				formData.append('files', file); // Append each file with a 'files[]' key
			});

			dispatch(archiveDataSubmit(formData, token));
			setUploadFlag(true);
		}
		else {
			toast.error('Please select of at least one file to send.');
		}
	};

	const handleCreateHistorySubmit = () => {
		console.log(data.id)
		dispatch(archiveHistorySubmit(data.id, date, [], selectedCategoriesOptions, '', description, '', '', '', '', '', '', 'evidence', url, selectedSourceTypeOption, '', token, userName.first_name, userName.last_name, [], '', ''));

		setURL('');
		setSelectedCategoriesOptions([]);
		setSelectedSourceTypeOption('');
		setSelectedFiles(null);  // for multiple file system
		setPreviews([]);  // for multiple file system
		setDescription("");
		setDate('');

	};


	useEffect(() => {
		if (data.success) {
			toast.success("The files have been uploaded.");
			setUploadFlag(false);
		}
		if (data.error.message) {
			toast.error(data.error.message);
			setUploadFlag(false);
		}
	}, [data.error.message, data.success, dispatch]);

	useEffect(() => {
		if (data.success && data.id) {
			handleCreateHistorySubmit();
		}
	}, [data.id, data.success,]);


	return (
		<Layout>
			<Box
				px={{ base: '2', md: '4', }}
				py={{ base: '4', md: '8' }}
				maxW={{ base: "98vw", md: "80vw" }} mx="auto"
			>

				<Heading
					as="h1"
					textAlign="left"
					size="xl"
					color="gray.800"
					mb={6}
					fontFamily="Montserrat, sans-serif"
				>
					Upload to Archive
				</Heading>

				<Box bg="white" shadow="md" rounded="lg" p={6} mb={8}>
					<form onSubmit={handleSubmit}>
						<VStack spacing={6} align="stretch">
							{/* URL */}
							<FormControl id="location">
								<FormLabel fontSize="sm" fontWeight="medium" color="gray.700" mb={1}>
									URL
								</FormLabel>
								<Input
									name={urlInputIdLinkToPdfPage}
									id={urlInputIdLinkToPdfPage}
									variant='filled'
									type="url"
									size={["md", "md", "lg"]}
									width='100%'
									p={3}
									placeholder={urlInputPlaceholderLinkToPdfPage}
									onChange={handleURLChange}
									value={url}
								/>
							</FormControl>

							{/* Categories */}
							<FormControl>
								<FormLabel htmlFor="document-type">Document Type</FormLabel>

								{/* <Box ml={5} w={['95%', '95%', '70%', '45%']}> */}
								<Box ml={5} >
									{/* <Button onClick={toggleDropdown}>
								{selectedCategoriesOptions.length > 0
								? `Total Selected (${selectedCategoriesOptions.length})`
								: `${categoriesInputPlaceholder}`}
							</Button> */}

									{/* {isOpen && ( */}
									<Box border="1px solid" borderColor="gray.200" borderRadius="md" mt={2} p={2} bg="white">
										{categoriesOptions.map((option) => (
											<Box key={option.value}>
												<Checkbox
													colorScheme='red'
													isChecked={selectedCategoriesOptions.includes(option.value)}
													onChange={() => handleCheckboxChange(option.value)}
												>
													{option.label}
												</Checkbox>
											</Box>
										))}
									</Box>
									{/* )} */}
								</Box>
							</FormControl>

							{/* Sources */}
							<FormControl id="sources">
								<FormLabel fontSize="sm" fontWeight="medium" color="gray.700" mb={1}>
									Sources (optional)
								</FormLabel>

								<Select
									placeholder={sourceTypeInputPlaceholderLinkToPdfPage}
									//variant="outline"

									//focusBorderColor="gray.500"
									borderRadiuyarn s="md"
									required
									onChange={handleSourceTypeChange}
									value={selectedSourceTypeOption}

								>
									{options.map((option) => (
										<option bgColor="gray.400" key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</Select>
							</FormControl>

							<FormControl>
								<FormLabel htmlFor="publication-date">Publication Date</FormLabel>
								<Input
									id="publication-date"
									type="date"
									onChange={setDate}
									value={date}
								/>
							</FormControl>

							<FormControl>
								<FormLabel htmlFor="description">Description</FormLabel>
								<Textarea
									id="description"
									placeholder="Provide a brief description of the document"
									rows={4}
									onChange={setDescription}
									value={description}
								/>
							</FormControl>

							<FormControl>
								<FormLabel htmlFor="file-upload">Upload File</FormLabel>
								<Box
									border="2px"
									borderColor="gray.300"
									borderStyle="dashed"
									rounded="md"
									p={5}
									textAlign="center"
								>
									<AttachmentIcon w={8} h={8} color="gray.400" />
									<Flex mt={2} justify="center" align="center">
										<label htmlFor="file-upload">
											<Text
												as="span"
												color="red.600"
												_hover={{ cursor: 'pointer', color: 'red.500' }}
												textDecoration="underline"
											>
												Upload a file
											</Text>
										</label>
										<Text mx={2}>or drag and drop</Text>
									</Flex>
									<Input id="file-upload" type="file" display="none" onChange={handleFileChange} />
									<Input
										type="file"
										onChange={handleFileChange}
										multiple   // for multiple file system
										accept="application/pdf,video/*, audio/*, image/*, application/msword"
										variant='filled'
									/>
									<Text fontSize="sm" color="gray.500">PDF, DOC, DOCX up to 2GB</Text>

									{error && <p style={{ color: 'red' }}>{error}</p>}

									{/* // for multiple file system */}
									{/* <Stack gap={[2, 5]} direction={['column', 'column', 'row']}> */}
									<Grid
										templateRows='repeat(1, 1fr)'
										templateColumns='repeat(4, 1fr)'
									>
										{previews.map((file, index) => (
											<GridItem
												colSpan={[4, 2, 1]}
												p='2'
												key={index}
											>
												{
													file.type.includes('pdf') ? (
														<Box h={['200px',]}>
															<iframe
																src={file.url}
																title={file.name}
																width="100%"
																height="100%"
															></iframe>
														</Box>
													) : file.type.includes('image') ? (
														<Box w={'100%'} mx={'auto'}>
															<img
																src={file.url}
																alt={file.name}
																style={{ maxWidth: '100%', height: 'auto' }}
															/>
														</Box>
													) : (
														<video
															controls
															width="500"
															src={file.url}
														>
															Your browser does not support the video tag.
														</video>
													)
												}
											</GridItem>
										))}
									</Grid>
								</Box>
							</FormControl>

							<Button
								size="lg"
								bg={"red.500"}
								color={"white"}
								_hover={{ bg: "red.900" }}
								px={4}
								py={1}
								borderRadius={'lg'}
								isDisabled={uploadFlag}
								type='submit'
								onClick={(event) => {
									handleSubmit(event);
								}}
							>
								{uploadFlag ? `${submitWaitingText}` : `${submitButtonText}`}
							</Button>
						</VStack>
					</form>
				</Box>

				<Box mt={12}>
					<Heading as="h2" size="lg" color="gray.800" mb={6} fontFamily="Montserrat, sans-serif">Contribution Guidelines</Heading>
					<Box bg="white" shadow="md" rounded="lg" p={6}>
						<Text textAlign='left' color="gray.600" mb={4}>
							Thank you for contributing to the Bangladesh Conflict Watch Archive. Please follow these guidelines when uploading documents:
						</Text>
						<UnorderedList align="left" pl={6} color="gray.600" spacing={2}>
							<ListItem>Ensure that you have the right to share the document.</ListItem>
							<ListItem>Provide accurate and complete metadata for easy searchability.</ListItem>
							<ListItem>Use clear and descriptive titles for your uploads.</ListItem>
							<ListItem>Include relevant keywords to improve discoverability.</ListItem>
							<ListItem>Double-check that your file is in a supported format (PDF, DOC, DOCX etc).</ListItem>
							<ListItem>Respect copyright and intellectual property rights.</ListItem>
						</UnorderedList>
						<Text textAlign="left" color="gray.600" mt={4}>
							Your contributions help build a comprehensive resource for conflict research and peace-building efforts in Bangladesh. We appreciate your support in growing our archive.
						</Text>
					</Box>
				</Box>
			</Box>
		</Layout>
	);
}
