import React, { useState } from "react"
import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalContent,
    ModalBody,
    ModalFooter,
    Button,
    VStack,
    Text,
    HStack,
    Stack,
    Tooltip,
    Box,
} from "@chakra-ui/react";
import { GrCompare } from "react-icons/gr";
import CompareTable from "./CompareTable";
import HistoryField from "./HistoryField";


const EditHistoryModal = (
    {
        isEditHistoryOpen,
        onEditHistoryClose,
        editHistory,
    }
) => {
    const [flag, setFlag] = useState(false);
    const [history, setHistory] = useState({});

    const [compareFlag, setCompareFlag] = useState(false);
    const [currentHistory, setCurrentHistory] = useState({});
    const [previousHistory, setPreviousHistory] = useState({});

    const editorInfo = {
        name: history.first_name && history.last_name
            ? `${history.first_name} ${history.last_name}`
            : `${history.user_id}`,
        date: history.review_date,
    };

    return (
        <Modal
            isOpen={isEditHistoryOpen}
            onClose={() => {
                onEditHistoryClose();
                setFlag(false);
                setCompareFlag(false);
            }}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{!compareFlag ? "Edit History " : "Comparison with Previous Data"} {!flag && !compareFlag && "(Click to See Details)"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack alignItems="flex-start">
                        {
                            !flag && !compareFlag &&
                            (
                                editHistory ?
                                    editHistory.map((singleHistory, i) =>
                                        <HStack
                                            key={i}
                                            width={'100%'}
                                            justify={'space-between'}
                                        >
                                            <Button
                                                bgColor="white"
                                                color="gray"
                                                px={2}
                                                m={0}
                                                onClick={() => {
                                                    setHistory(singleHistory);
                                                    setFlag(true);
                                                }}
                                            >
                                                <HStack>
                                                    <Text color={'gray.800'} fontWeight={'900'}>Reviewer:</Text>
                                                    <Text>{(singleHistory.first_name && singleHistory.last_name) ? `${singleHistory.first_name} ${singleHistory.last_name}` : `${singleHistory.user_id}`}</Text>
                                                </HStack>
                                            </Button>

                                            {
                                                (editHistory.length !== i + 1) &&
                                                <Tooltip hasArrow label='Compare' bg='gray.300' color='black' placement='top'>
                                                    <Button
                                                        bgColor="white"
                                                        color="red.700"
                                                        px={2}
                                                        m={0}
                                                        onClick={() => {
                                                            setCurrentHistory(editHistory[i]);
                                                            setPreviousHistory(editHistory[i + 1]);
                                                            setCompareFlag(true);
                                                        }}
                                                    >
                                                        <GrCompare size={25} />
                                                    </Button>
                                                </Tooltip>
                                            }
                                        </HStack>
                                    )
                                    :
                                    <Text>No History found for this data.</Text>
                            )
                        }

                        {/* compare history  */}
                        {
                            !flag && compareFlag &&
                            <Box width="100%">
                                <CompareTable
                                    currentHistory={currentHistory}
                                    previousHistory={previousHistory}
                                />
                            </Box>
                        }

                        {/* history data show  */}
                        {
                            flag && history &&
                            <VStack alignItems="flex-start">
                                <HistoryField label="Review Date" value={history.review_date} />
                                <HistoryField label="Reviewer" value={`${history.first_name} ${history.last_name}`} />
                                <HistoryField label="Data Type" value={history.data_type} />
                                <HistoryField label="District" value={history.location} isEdited={editorInfo} />
                                <HistoryField label="Upazila" value={history.upazila} isEdited={editorInfo} />
                                <HistoryField label="Source URL" value={history.source_url} isEdited={editorInfo} />
                                <HistoryField label="Categories" value={history.categories} isEdited={editorInfo} />
                                <HistoryField label="Description" value={history.description} isEdited={editorInfo} />
                                <HistoryField label="Short Description" value={history.short_description} isEdited={editorInfo} />
                                <HistoryField label="Source Type Description" value={history.source_type} isEdited={editorInfo} />
                                <HistoryField label="Comment" value={history.comment} isEdited={editorInfo} />
                                <HistoryField label="Name" value={history.name} isEdited={editorInfo} />
                                <HistoryField label="Death" value={history.death} isEdited={editorInfo} />
                                <HistoryField label="Injury" value={history.injury} isEdited={editorInfo} />
                                <HistoryField label="Property Value" value={history.property_value} isEdited={editorInfo} />
                                <HistoryField label="Number Of Houses" value={history.number_of_houses} isEdited={editorInfo} />
                                <HistoryField label="Property Vandalized" value={history.property_vandalized} isEdited={editorInfo} />
                                <HistoryField label="Review Status" value={history.review_status} isEdited={editorInfo} />
                                <HistoryField label="Tags" value={history.tags} isEdited={editorInfo} />
                                <HistoryField label="Events" value={history.events} isEdited={editorInfo} />
                            </VStack>
                        }
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    {
                        (flag || compareFlag) &&
                        <Button 
                            colorScheme="red" 
                            bgColor="red.700"
                            color="white"
                            _hover={{ bg: "red.900", color: "white" }}
                            mr={3}
                            onClick={() => {
                                setFlag(false);
                                setCompareFlag(false);
                            }}
                        >
                            Back
                        </Button>
                    }
                    <Button 
                        variant="ghost"
                        bgColor="red.700"
                        color="white"
                        _hover={{ bg: "red.900", color: "white" }}
                        onClick={() => {
                            onEditHistoryClose();
                            setFlag(false);
                            setCompareFlag(false);
                        }}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default EditHistoryModal;