import {
    REQUEST_SIGNUP,
    SUCCESS_SIGNUP,
    FAILURE_SIGNUP,
    REQUEST_LOGIN,
    SUCCESS_LOGIN,
    FAILURE_LOGIN,
    REQUEST_LOGOUT,
    SUCCESS_LOGOUT,
    FAILURE_LOGOUT,
    REQUEST_UPDATE_PROFILE,
    SUCCESS_UPDATE_PROFILE,
    FAILURE_UPDATE_PROFILE,

    REQUEST_GET_USER_HISTORY,
    SUCCESS_GET_USER_HISTORY,
    FAILURE_GET_USER_HISTORY,

    REQUEST_UPDATE_PASSWORD,
    SUCCESS_UPDATE_PASSWORD,
    FAILURE_UPDATE_PASSWORD,

    REQUEST_USER_LIST,
    SUCCESS_UPDATE_USER_LIST,
    FAILURE_UPDATE_USER_LIST,
    REQUEST_USER_TYPE_CHANGE,
    SUCCESS_UPDATE_USER_TYPE,
    FAILURE_UPDATE_USER_TYPE,
    REQUEST_USER_BAN,
    SUCCESS_USER_BAN,
    FAILURE_USER_BAN,
    UPDATE_SESSION_EXPIRY,
} from "../Constant/login";


export const requestSignup = () => ({
    type: REQUEST_SIGNUP,
})
export const signupSuccess = (data) => ({
    type: SUCCESS_SIGNUP,
    payload: data,
})
export const signupFailure = (error) => ({
    type: FAILURE_SIGNUP,
    payload: error,
})

export const requestLogin = () => ({
    type: REQUEST_LOGIN,
})
export const loginSuccess = (data) => ({
    type: SUCCESS_LOGIN,
    // payload: data.logins[0],
    payload: data,
})
export const loginFailure = (error) => ({
    type: FAILURE_LOGIN,
    payload: error,
})

export const requestLogout = () => ({
    type: REQUEST_LOGOUT,
})
export const logoutSuccess = (data) => ({
    type: SUCCESS_LOGOUT,
    payload: data,
})
export const logoutFailure = (error) => ({
    type: FAILURE_LOGOUT,
    payload: error,
})

export const requestUpdateProfile = () => ({
    type: REQUEST_UPDATE_PROFILE,
    // payload: token,
})
export const updateProfileSuccess = (data) => ({
    type: SUCCESS_UPDATE_PROFILE,
    payload: data.logins[0],
})
export const updateProfileFailure = (error) => ({
    type: FAILURE_UPDATE_PROFILE,
    payload: error,
})

export const requestGetUserHistory = () => ({
    type: REQUEST_GET_USER_HISTORY,
    // payload: token,
})
export const getUserHistorySuccess = (data) => ({
    type: SUCCESS_GET_USER_HISTORY,
    payload: data,
})
export const getUserHistoryFailure = (error) => ({
    type: FAILURE_GET_USER_HISTORY,
    payload: error,
})

export const requestUpdatePassword = () => ({
    type: REQUEST_UPDATE_PASSWORD,
    // payload: token,
})
export const updatePasswordSuccess = (data) => ({
    type: SUCCESS_UPDATE_PASSWORD,
    payload: data,
})
export const updatePasswordFailure = (error) => ({
    type: FAILURE_UPDATE_PASSWORD,
    payload: error,
})

export const updateSessionExpiry = (nextDay) => ({
     type: UPDATE_SESSION_EXPIRY,
     payload: nextDay,
 })

export const requestUserList = () => ({
    type: REQUEST_USER_LIST,
})
export const getUserListSuccess = (data) => ({
    type: SUCCESS_UPDATE_USER_LIST,
    payload: data,
})
export const getUserListFailure = (error) => ({
    type: FAILURE_UPDATE_USER_LIST,
    payload: error,
})

export const requestUserTypeChange = () => ({
    type: REQUEST_USER_TYPE_CHANGE,
})
export const getUserTypeChangeSuccess = (data) => ({
    type: SUCCESS_UPDATE_USER_TYPE,
    payload: data,
})
export const getUserTypeChangeFailure = (error) => ({
    type: FAILURE_UPDATE_USER_TYPE,
    payload: error,
})

export const requestUserBan = () => ({
    type: REQUEST_USER_BAN,
})
export const userBanSuccess = (data) => ({
    type: SUCCESS_USER_BAN,
    payload: data,
})
export const userBanFailure = (error) => ({
    type: FAILURE_USER_BAN,
    payload: error,
})

