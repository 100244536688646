import {
    REQUEST_GET_LIST_EVENTS,
    SUCCESS_GET_LIST_EVENTS,
    FAILURE_GET_LIST_EVENTS,
    REQUEST_CREATE_EVENT,
    SUCCESS_CREATE_EVENT,
    FAILURE_CREATE_EVENT,
    REQUEST_DELETE_EVENT,
    SUCCESS_DELETE_EVENT,
    FAILURE_DELETE_EVENT,
} from "../Constant/events";


export const requestGetListEvents = () => ({
    type: REQUEST_GET_LIST_EVENTS,
})
export const getListEventsSuccess = (data) => ({
    type: SUCCESS_GET_LIST_EVENTS,
    payload: data,
})
export const getListEventsFailure = (error) => ({
    type: FAILURE_GET_LIST_EVENTS,
    payload: error,
})


export const requestCreateEvent = () => ({
    type: REQUEST_CREATE_EVENT,
})
export const createEventSuccess = (data) => ({
    type: SUCCESS_CREATE_EVENT,
    payload: data,
})
export const createEventFailure = (error) => ({
    type: FAILURE_CREATE_EVENT,
    payload: error,
})


export const requestDeleteEvent = () => ({
    type: REQUEST_DELETE_EVENT,
})
export const deleteEventSuccess = (data) => ({
    type: SUCCESS_DELETE_EVENT,
    payload: data,
})
export const deleteEventFailure = (error) => ({
    type: FAILURE_DELETE_EVENT,
    payload: error,
})