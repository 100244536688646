import { combineReducers } from "redux";
import {
    REQUEST_GET_LIST_DOCUMENTS,
    SUCCESS_GET_LIST_DOCUMENTS,
    FAILURE_GET_LIST_DOCUMENTS,
    REQUEST_UPDATE_DOCUMENT,
    SUCCESS_UPDATE_DOCUMENT,
    FAILURE_UPDATE_DOCUMENT,
    REQUEST_UPDATE_EDIT_HISTORY_DOCUMENT,
    SUCCESS_UPDATE_EDIT_HISTORY_DOCUMENT,
    FAILURE_UPDATE_EDIT_HISTORY_DOCUMENT,
    REQUEST_FILE_DOWNLOAD,
    SUCCESS_FILE_DOWNLOAD,
    FAILURE_FILE_DOWNLOAD,
    REQUEST_GET_DOCUMENT_EDIT_HISTORY,
    SUCCESS_GET_DOCUMENT_EDIT_HISTORY,
    FAILURE_GET_DOCUMENT_EDIT_HISTORY,
    REQUEST_GET_SEARCH_RESULT,
    SUCCESS_GET_SEARCH_RESULT,
    FAILURE_GET_SEARCH_RESULT,
} from "../Constant/listDocuments";

const initialState = {
    listDocuments: [],
    error: "",
    loading: false,
    fileDownload: {
        success: '',
        error: "",
    },
    dataUpdate: {
        success: '',
        error: "",
    },
    dataUpdateEditHistory: {
        success: '',
        error: "",
    },
    documentEditHistory: [],
    isFetching: false,
}

const listDocumentsData = (state = initialState, action) => {
    switch (action.type) {

        case REQUEST_GET_LIST_DOCUMENTS:
        case REQUEST_GET_SEARCH_RESULT:
            return {
                ...state,
                listDocuments: [],
                error: '',
            }
        case SUCCESS_GET_LIST_DOCUMENTS:
        case SUCCESS_GET_SEARCH_RESULT:
            return {
                ...state,
                listDocuments: action.payload,
                error: '',
            }
        case FAILURE_GET_LIST_DOCUMENTS:
        case FAILURE_GET_SEARCH_RESULT:
            return {
                ...state,
                listDocuments: [],
                error: action.payload,
            }

        default:
            return state;
    }
};

const downloadFile = (state = initialState.fileDownload, action) => {
    switch (action.type) {

        case REQUEST_FILE_DOWNLOAD:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_FILE_DOWNLOAD:
            return {
                ...state,
                // success: action.payload.message,
                success: action.payload,
                error: '',
            }
        case FAILURE_FILE_DOWNLOAD:
            return {
                ...state,
                success: '',
                error: action.payload,
            }

        default:
            return state;
    }
};

const updateData = (state = initialState.dataUpdate, action) => {
    switch (action.type) {

        case REQUEST_UPDATE_DOCUMENT:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_UPDATE_DOCUMENT:
            return {
                ...state,
                success: action.payload.message,
                error: '',
            }
        case FAILURE_UPDATE_DOCUMENT:
            return {
                ...state,
                success: '',
                error: action.payload,
            }

        default:
            return state;
    }
};

const updateEditHistoryData = (state = initialState.dataUpdateEditHistory, action) => {
    switch (action.type) {

        case REQUEST_UPDATE_EDIT_HISTORY_DOCUMENT:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_UPDATE_EDIT_HISTORY_DOCUMENT:
            return {
                ...state,
                success: action.payload.message,
                error: '',
            }
        case FAILURE_UPDATE_EDIT_HISTORY_DOCUMENT:
            return {
                ...state,
                success: '',
                error: action.payload,
            }

        default:
            return state;
    }
};

const documentEditHistoryData = (state = initialState, action) => {
    switch (action.type) {

        case REQUEST_GET_DOCUMENT_EDIT_HISTORY:
            return {
                ...state,
                documentEditHistory: [],
                error: '',
            }
        case SUCCESS_GET_DOCUMENT_EDIT_HISTORY:
            return {
                ...state,
                documentEditHistory: action.payload,
                error: '',
            }
        case FAILURE_GET_DOCUMENT_EDIT_HISTORY:
            return {
                ...state,
                documentEditHistory: [],
                error: action.payload,
            }

        default:
            return state;
    }
};

const isFetching = (state = initialState.isFetching, action) => {
    switch (action.type) {
        case REQUEST_GET_LIST_DOCUMENTS:
        case REQUEST_UPDATE_DOCUMENT:
        case REQUEST_UPDATE_EDIT_HISTORY_DOCUMENT:
        case REQUEST_GET_DOCUMENT_EDIT_HISTORY:
            return true;

        case SUCCESS_GET_LIST_DOCUMENTS:
        case SUCCESS_UPDATE_DOCUMENT:
        case SUCCESS_UPDATE_EDIT_HISTORY_DOCUMENT:
        case SUCCESS_GET_DOCUMENT_EDIT_HISTORY:
        case FAILURE_GET_LIST_DOCUMENTS:
        case FAILURE_UPDATE_DOCUMENT:
        case FAILURE_UPDATE_EDIT_HISTORY_DOCUMENT:
        case FAILURE_GET_DOCUMENT_EDIT_HISTORY:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    listDocumentsData,
    downloadFile,
    updateData,
    updateEditHistoryData,
    documentEditHistoryData,
    isFetching,
});

export const selectListDocuments = state => state;
export const selectUpdateData = state => state.updateData;
export const selectUpdateEditHistoryData = state => state.updateEditHistoryData;
export const selectDownloadFile = state => state.downloadFile;
export const selectDocumentEditHistory = state => state;


// export const selectLine = state => state.lines;
// export const selectNewLine = state => state.lines;
// export const selectTranslatedLine = state => state.lines;
