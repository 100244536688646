import React, { useEffect, useState } from 'react';
import {
	Icon,
	Stack,
	Text,
	Box,
	Button,
	Select,
	FormControl,
	HStack,
	VStack,
	Flex,
} from '@chakra-ui/react';
// import { BookOpen } from 'react-feather';
//import { DownloadIcon } from "@chakra-ui/icons";
import { FaUser } from "react-icons/fa";
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { selectToken, selectUpdateMessage, selectUserList } from '../Redux/Reducer';
import { getUserListData, userBan, userTypeChange } from '../Redux/Thunk/Login';
import Layout from '../Layout/Layout';

const ListItem = ({ handleStateChange, handleUserTypeChange, handleBan, td, currentPage, itemsPerPage, i }) => {

	const [selectedOption, setSelectedOption] = useState('');
	const [categoriesOptions, setCategoriesOptions] = useState([
		{ value: 'public', label: 'Pending User' },
		{ value: 'volunteer', label: 'Reporter' },
		{ value: 'admin', label: 'Analyst' },
	]);

	const handleChange = (state, id) => {
		handleStateChange(state, id);
		setSelectedOption(state);
	};

	let place_holder_for_user_value = "";

	if (td.account_type === "public") {
		place_holder_for_user_value = "Pending User";
	} else if (td.account_type === "volunteer") {
		place_holder_for_user_value = "Reporter"
	} else if (td.account_type === "admin") {
		place_holder_for_user_value = "Analyst";
	} else if (td.account_type === "super-admin") {
		place_holder_for_user_value = "Admin"
	}

	return (
		<Box key={i} bg="white" shadow="md" rounded="lg" p={4} mb={4}>
			<Flex
				mx="auto"
				direction={{ base: 'column', md: 'row' }}
				justify={{ base: 'center', md: 'space-between' }}
				//direction={['column', 'column', 'column']}
				//margin={3}
				//padding={2}
				//borderWidth={1}
				//borderRadius="md"
				//borderColor="black"
				key={td.id}

				backgroundColor='white'
			>
				{/*<Text w={["100%", "100%", "5%"]} fontSize={['xs', 'sm', 'md']} fontWeight="bold" color="black" textAlign="center" alignSelf="center">
				{i + 1 + (currentPage - 1) * itemsPerPage}
			</Text>

			<VStack alignSelf="center" alignItems="flex-start" gap={2} w={["100%", "100%", "70%"]}>
				<Text fontWeight="semibold" wordBreak="break-word" whiteSpace="normal">{td.first_name} {td.last_name}</Text>
			</VStack>

			<VStack alignSelf="center" alignItems="flex-start" gap={2} w={["100%", "100%", "70%"]}>
				<Text fontWeight="semibold" wordBreak="break-word" whiteSpace="normal">{td.email}</Text>
			</VStack>*/}

				<HStack w={{ base: "100%", md: "60%" }} align={'flex-start'} mr={2}>
					<Icon as={FaUser} color="red.600" mt={1} pb={1} />

					<VStack
						align="flex-start"
						gap={1}
						w={["100%", "100%", "100%"]}
					>

						<Text
							//fontWeight="semibold"
							wordBreak="break-word"
							whiteSpace="normal"
							color="gray.600"
							fontSize="sm"
							mb={{ base: 1, md: 2 }}
						>
							Name: {td.first_name} {td.last_name}
						</Text>

						<Text
							//fontWeight="semibold"
							wordBreak="break-word"
							whiteSpace="normal"
							color="gray.600"
							fontSize="sm"
							mb={{ base: 1, md: 2 }}
						>
							Email: {td.email}
						</Text>

					</VStack>
				</HStack>


				{/*<Box w={["100%", "100%", "25%"]} p="1" alignContent={"center"}>
				
				<HStack
					direction={{ xs: 'column', sm: 'column', lg: 'row', md: 'row' }}
				>
					<Select
						placeholder={place_holder_for_user_value}
						size={['md', 'md', 'lg']}
						variant="filled"
						focusBorderColor="blue.500"
						borderRadius="md"
						boxShadow="sm"
						onChange={(e) => handleChange(e.target.value, td.id)}
						value={selectedOption}
					>
						{categoriesOptions.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</Select>

					<Button
						bgColor="blue.500"
						color="white"
						_hover={{ bg: "blue.400", color: "white" }}
						p={6}
						// mb={3}
						ml={1}
						onClick={() => handleUserTypeChange()}
					>
						Approve
					</Button>

					<Button
						bgColor="white"
						color="blue.500"
						_hover={{ bg: "blue.300", color: "white" }}
						py={6}
						// mb={3}
						ml={1}
						onClick={() => handleBan(td.id)}
					>
						Ban
					</Button>
					
				</HStack>
			</Box>
			*/}

				<Box m={0} pl={1} display="flex" flexDirection={["column", "row", "column", "row"]} alignItems={{ base: "flex-start", lg: "flex-end" }} ml={{ base: 5, md: 'none' }} justifyContent="center">
					<HStack>
						<Select
							//w={{base:"100%",md:"80%"}}
							placeholder={place_holder_for_user_value}
							//size={{ base: "sm", md: "md" }}
							// variant="filled"
							//fontSize={{ base: "sm", md: "md" }}
							_placeholder={{
								color: "gray.800", // Responsive color for the placeholder text
								fontSize: ["sm", "md", "lg"], // Adjust font size for mobile, tablet, desktop
							}}

							focusBorderColor="gray.900"
							borderRadius="md"
							boxShadow="sm"
							borderColor={"gray.900"}
							mx={1}
							mb={3}
							onChange={(e) => handleChange(e.target.value, td.id)}
							value={selectedOption}
						>
							{categoriesOptions.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</Select>
					</HStack>

					<HStack >
						<Button
							isDisabled={"waiting" === td.status ? true : false}
							//bgColor="blue.500"
							//color="white"
							//_hover={{ bg: "blue.400", color: "white" }}
							mb={3}
							//onClick={() => handleDownload(td.file_names)}
							onClick={() => handleUserTypeChange()}
							//fontSize={{ base: "sm", md: "md" }}
							//size={{ base: "sm", md: "md" }}
							ml={1}
							variant="outline"
							//leftIcon={<DownloadIcon />}
							colorScheme="red"
						>
							Approve
						</Button>

						<Button
							//isDisabled={"waiting" === td.status ? true : false}
							//bgColor="blue.500"
							//color="white"
							//_hover={{ bg: "blue.400", color: "white" }}bgColor="white"
							color="red.600"
							_hover={{ bg: "red.600", color: "white" }}

							mb={3}
							onClick={() => handleBan(td.id)}
						//fontSize={{ base: "sm", md: "md" }}
						//size={{ base: "sm", md: "md" }}

						//variant="outline"
						//leftIcon={<DownloadIcon />}
						//colorScheme="red"
						>
							Ban
						</Button>

					</HStack>

				</Box>

			</Flex>
		</Box>
	)
}

const UserList = () => {
	// const { innerHeight } = window;

	const dispatch = useDispatch();
	const token = useSelector(state => selectToken(state));
	const update = useSelector(state => selectUpdateMessage(state));
	const filteredData = useSelector(state => selectUserList(state));

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);  // Default to 5 items per page

	const handlePageChange = (page) => {
		// if (page >= 1 && page <= totalPages) {
		if (page >= 1) {
			setCurrentPage(page);
		}
	};


	//fetch user_list and store it to redux state
	useEffect(() => {
		dispatch(getUserListData((currentPage - 1) * itemsPerPage, itemsPerPage, token));
	}, [currentPage, dispatch, itemsPerPage, token]);

	// // data set 
	// useEffect(() => {
	// 	if (data && data.listArchive) {
	// 		setTableData(data.listArchive);
	// 	}
	// }, [data]);
	// // console.log(selectedCategoriesOptions);


	const [id, setId] = useState('');
	const [account_type, setAccountType] = useState("public");

	//	let selected_user_role = [];	
	const handleStateChange = (state, id) => {
		setId(id);
		setAccountType(state);
	};

	// change user's account privilege
	const handleUserTypeChange = () => {
		if (id !== '') {
			dispatch(userTypeChange(id, account_type, token));
		}
	};

	// for ban
	const [banFlag, setBanFlag] = useState(false);
	const handleEdit = (id) => {
		setBanFlag(true);
		dispatch(userBan(id, token));
	};

	// toast 
	useEffect(() => {
		if (update.success) {
			// if (update.success === "user updated") {
			// toast.success(update.success);
			if (!banFlag) {
				toast.success("User access has been updated");
			}
			else {
				toast.success("User has been banned");
				setBanFlag(false);
			}
		}
		/*
			if (update.error?.message) {
				toast.error(update.error.message);
			}
		*/
	}, [update, banFlag]);


	return (
		<Layout>
			<Box
				pb={{ base: '2', md: '4' }}
				px={{ base: '4', md: '8' }}
				maxW={{ base: '98vw', md: '80vw' }}
				mx="auto"
			//minH={innerHeight * 0.756}
			>
				<Box p={[2, 6]} w="100%">
					<Text fontSize="lg" fontWeight="semibold" color="black" textAlign="left" my={1} p={1}>
						List of Users
					</Text>

					{/* Document list */}
					{/* {paginatedData.length > 0 ? ( */}
					{filteredData && filteredData.listUser && filteredData.listUser.length > 0 ? (
						// paginatedData.map((td, i) => (
						filteredData.listUser.map((td, i) => (
							<ListItem
								td={td}
								i={i}
								handleUserTypeChange={handleUserTypeChange}
								handleStateChange={handleStateChange}
								handleBan={handleEdit}
								key={td.id}
								currentPage={currentPage}
								itemsPerPage={itemsPerPage}
							/>
						))
					) : (
						<Text textAlign="center" my={4}>
							No data found for the current search/filter.
						</Text>
					)}

					{/* Pagination controls */}
					<Stack gap={[2, 5]} marginY={3} mt={4}
						direction={['column', 'row']}
						justify={"space-between"}
					>
						<FormControl width={["100%", "40%", "30%", "20%"]}>
							<Select
								placeholder="Select items per page"
								size="md"
								variant="filled"
								onChange={(e) => {
									setItemsPerPage(Number(e.target.value));
									setCurrentPage(1);  // Reset to the first page when items per page changes
								}}
							>
								<option value={10}>10</option>
								<option value={20}>20</option>
								<option value={50}>50</option>
								<option value={100}>100</option>
							</Select>
						</FormControl>

						<HStack justify="center" width={["100%", "60%", "40%", "30%"]}>
							<Button
								onClick={() => handlePageChange(currentPage - 1)}
								isDisabled={currentPage === 1}
							>
								Previous
							</Button>

							<Text>
								Page {currentPage}
							</Text>

							<Button
								onClick={() => handlePageChange(currentPage + 1)}
							>
								Next
							</Button>
						</HStack>
					</Stack>
				</Box>
			</Box >
		</Layout>
	);
};

export default UserList;
