import React, { useEffect, useState } from "react";
import { Box, Input, List, ListItem, Button, Tag, TagLabel, TagCloseButton, HStack, Text } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import toast from 'react-hot-toast';

import { selectToken, selectListTags, selectActionTags } from "../Redux/Reducer";
import { createTag, deleteTag, getListTagsData } from "../Redux/Thunk/Tags";

import Layout from "../Layout/Layout";


const SearchBarWithSuggestions = ({ embedded, onlySearch, deleteButton, selectedTags, setSelectedTags }) => {

	const { innerHeight } = window;

	const dispatch = useDispatch();

	const tags = useSelector(state => selectListTags(state));
	const token = useSelector(state => selectToken(state));
	const data = useSelector(state => selectActionTags(state));

	// const [filteredOptions, setFilteredOptions] = useState(tags);
	const [filteredOptions, setFilteredOptions] = useState([]);
	const [dataTags, setDataTags] = useState([]);

	useEffect(() => {
		dispatch(getListTagsData(0, 25, token));
	}, [dispatch, token]);


	// useEffect(() => {
	// 	setFilteredOptions(tags);
	// }, [tags]);

	const [inputValue, setInputValue] = useState("");

	const handleInputChange = (e) => {
		const value = e.target.value;
		setInputValue(value);

		if (value.length > 0) {
			const filtered = tags.filter(option =>
				option.name.toLowerCase().includes(value.toLowerCase())
			);
			setFilteredOptions(filtered);
		} else {
			// setFilteredOptions(tags);
			setFilteredOptions([]);
		}
	};

	const handleTagClick = (tag) => {
		if (!selectedTags.includes(tag)) {
			setSelectedTags([...selectedTags, tag]);
		}
		setInputValue("");
		// setFilteredOptions(tags);
		setFilteredOptions([]);
	};

	const handleCreateTag = (e, is_new) => {
		if (inputValue && !selectedTags.includes(inputValue)) {
			const newTags = [...dataTags, inputValue];
			setDataTags(newTags);
			setSelectedTags([...selectedTags, inputValue]);
			if (is_new === true) {
				dispatch(createTag(inputValue, token));
				dispatch(getListTagsData(0, 49, token));
			}
		}
		setInputValue("");
		// setFilteredOptions(tags);
		setFilteredOptions([]);
	};

	const handleRemoveTag = (tagToRemove) => {
		setSelectedTags(selectedTags.filter(tag => tag !== tagToRemove));
	};

	useEffect(() => {
		if (data.success) {
			toast.success(data.success);

			if (tagForDelete) {
				handleRemoveTag(tagForDelete);
			}
		}
		if (data.error.message) {
			toast.error(data.error.message);
		}
	}, [data.error.message, data.success]);


	const [tagForDelete, setTagForDelete] = useState("");
	const handleDeleteTag = (tag) => {
		// console.log(tag);

		dispatch(deleteTag(tag, token));

		setTagForDelete(tag);
	};


	return (
		<>
			<Box
				maxW={{ base: "98vw", md: "80vw" }}
				px={{ base: !embedded ? 2 : 0, md: !embedded ? 4 : 0 }}
				py={{ base: !embedded ? 4 : 0, md: !embedded ? 8 : 0 }}
				margin="0 auto"
				mt={!embedded ? 5 : 0}
				position="relative"
				minH={!embedded ? (innerHeight * 0.738) : 0}
			>
				{
					!embedded &&
					<Text
						fontWeight="semibold"
						wordBreak="break-word"
						whiteSpace="normal"
						color="gray.600"
						fontSize={["sm", 'sm', 'md']}
						mb={{ base: 1, md: 4 }}
						textAlign={'center'}
					>
						Please search for existing data using the search field. In case, you can not find your desired tag, you can create one using the "Create Tag" button.
					</Text>
				}

				{/* Search Bar */}
				<Box display="flex" flexDirection="row" gap={2}>
					<Input
						placeholder="Search Tags..."
						value={inputValue}
						onChange={handleInputChange}
						size="md"
						focusBorderColor="#A9A9A9"
					/>

					{
						!onlySearch &&
						<Button
							bgColor={'red.600'}
							color={'white'}
							_hover={{ bg: "red.800", color: "white" }}
							p={4}
							borderRadius={'lg'}
							onClick={(e) => handleCreateTag(e, filteredOptions.length === 0)}
							type='submit'
						>
							{/* Create Tag */}
							{filteredOptions.length === 0 ? "Create Tag" : "Add Tag"}
						</Button>
					}
				</Box>

				{/* Suggestions List */}
				{filteredOptions.length > 0 && (
					<List
						position="relative"
						width="100%"
						bg="white"
						mt="2"
						borderRadius="md"
						border="1px solid #e2e8f0"
						maxHeight="150px"
						overflowY="auto"
						boxShadow="md"
						backgroundColor="#FFFF"
					>
						{filteredOptions.map((option, index) => (
							<ListItem
								key={index}
								padding="8px"
								_hover={{ backgroundColor: "#A9A9A9", cursor: "pointer" }}
								onClick={() => handleTagClick(option.name)}
							>
								{option.name}
							</ListItem>
						))}
					</List>
				)}

				{/* Selected Tags Display */}
				{
					!deleteButton &&
					<Box my={!embedded ? 4 : 2} display="flex" flexWrap="wrap" gap={2}>
						{selectedTags.map((tag, index) => (
							<Tag
								key={index}
								size="md"
								borderRadius="full"
								variant="solid"
								colorScheme="gray"
							>
								<TagLabel>{tag}</TagLabel>
								<TagCloseButton onClick={() => handleRemoveTag(tag)} />
							</Tag>
						))}
					</Box>
				}

				{/* Selected Tags Display */}
				{
					deleteButton &&
					<Box my={!embedded ? 4 : 2} display="flex" flexWrap="wrap" gap={2} flexDirection={['column', 'row', "row"]}>
						{selectedTags.map((tag, index) => (
							<HStack border={'dotted'} p={2} borderRadius={5} borderWidth={1} justify={'space-between'} key={index}>
								<Tag
									size="md"
									borderRadius="full"
									variant="solid"
									colorScheme="gray"
								>
									<TagLabel>{tag}</TagLabel>
									<TagCloseButton onClick={() => handleRemoveTag(tag)} />
								</Tag>

								<Button
									bgColor={'red.600'}
									color={'white'}
									_hover={{ bg: "red.800", color: "white" }}
									p={2}
									size={'xs'}
									borderRadius={'lg'}
									onClick={(e) => handleDeleteTag(tag)}
									type='submit'
								>
									Delete
								</Button>
							</HStack>
						))}
					</Box>
				}
			</Box >

		</>
	);
};


const Tags = ({ embedded, onlySearch, deleteButton, listSelectedTags, listSetSelectedTags, listSearchSelectedTags, setListSearchSelectedTags }) => {
	// console.log("list", listSelectedTags);
	const [selectedTags, setSelectedTags] = useState([]);

	return (
		<>
			{
				!embedded &&
				<Layout>
					<SearchBarWithSuggestions
						embedded={embedded}
						onlySearch={false}
						deleteButton={true}
						selectedTags={selectedTags}
						setSelectedTags={setSelectedTags}
					/>
				</Layout>
			}

			{
				embedded && !onlySearch &&
				<SearchBarWithSuggestions
					embedded={embedded}
					onlySearch={onlySearch}
					deleteButton={deleteButton}
					selectedTags={listSelectedTags}
					setSelectedTags={listSetSelectedTags}
				/>
			}

			{
				embedded && onlySearch &&
				<SearchBarWithSuggestions
					embedded={embedded}
					onlySearch={onlySearch}
					deleteButton={deleteButton}
					selectedTags={listSearchSelectedTags}
					setSelectedTags={setListSearchSelectedTags}
				/>
			}
		</>
	);
}


export default Tags;