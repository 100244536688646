import React from "react";
import { VStack, HStack, Text } from "@chakra-ui/react";

const CompareTable = ({ currentHistory, previousHistory }) => {

    const keys = Object.keys(currentHistory || {});

    // Function to render the current and previous values with differences highlighted
    const renderValue = (value, previousValue) => {
        if (Array.isArray(value)) {
            const uniqueValues = new Set(previousValue); // Values in previous array
            return value.map((val, index) => {
                const isNew = !uniqueValues.has(val); // Highlight only if not in previous array
                return (
                    <Text
                        as="span"
                        key={index}
                        color={isNew ? "blue.500" : "gray.500"}
                    >
                        {val}{index < value.length - 1 ? ", " : ""}
                    </Text>
                );
            });
        }
        return (
            <Text as="span" color={value !== previousValue ? "blue.500" : "gray.500"}>
                {value ?? "N/A"}
            </Text>
        );
    };

    const isArrayEqual = (arr1, arr2) =>
        Array.isArray(arr1) &&
        Array.isArray(arr2) &&
        arr1.length === arr2.length &&
        arr1.every((val, index) => val === arr2[index]);

    return (
        <VStack>
            <HStack width={'100%'} fontWeight="bold">
                <Text width={'20%'}>Field</Text>
                <Text width={'40%'}>Current</Text>
                <Text width={'40%'}>Previous</Text>
            </HStack>

            {keys.length === 0 ? (
                <Text>No data available</Text>
            ) : (
                keys.map((key) => (
                    <React.Fragment key={key}>
                        {key !== 'id' && key !== 'user_id' &&
                            (!Array.isArray(currentHistory[key]) || !isArrayEqual(currentHistory[key], previousHistory[key])) &&
                            currentHistory[key] !== previousHistory[key] && (
                                <HStack width={'100%'} key={key}>
                                    <Text fontWeight="bold" width={'20%'}>{key}</Text>

                                    <Text width={'40%'}>
                                        <span>{renderValue(currentHistory[key], previousHistory[key])}</span>
                                    </Text>

                                    <Text width={'40%'}>
                                        <span>{renderValue(previousHistory[key], currentHistory[key])}</span>
                                    </Text>
                                </HStack>
                            )}
                    </React.Fragment>
                ))
            )}
        </VStack>
    );
}

export default CompareTable;
