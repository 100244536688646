import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsSuperAdmin } from '../Redux/Reducer';


const SuperAdminRoute = ({ children }) => {
    // const isSuperAdmin = true;
    const isSuperAdmin = useSelector(state => selectIsSuperAdmin(state));

    if (isSuperAdmin) {
        return children;
    }
    return <Navigate to='/home'></Navigate>;
};

export default SuperAdminRoute;