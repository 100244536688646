import React, { Fragment } from 'react';
import { Box, Button, VStack, HStack, Text, Container, Link, Image, Stack, } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ExternalLinkIcon } from '@chakra-ui/icons'
import DescriptionCards from '../Components/DescriptionCards';
import Navbar from '../Components/Navbar';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '../Redux/Reducer';


const LandingPage = () => {
    const navigate = useNavigate();
    const loggedIn = useSelector(state => selectIsLoggedIn(state));

    return (
        <Fragment>
            {loggedIn && <Navbar />}

            <Container
                mt={[10, 12, 14, 16]}
                p={[1, 3, 5, 8]}
                maxWidth={"1000px"}
                mx={'auto'}
                fontWeight='900'
            >
                {/* <Box
                    // mb={[3, 4, 5, 6]}
                    // px={[2, 4, 7, 10]}
                    // py={[2, 3, 4, 5]}
                    // w={'30%'}
                    textAlign="left"
                    ml={'5%'}
                // borderWidth={2}
                // borderRadius="lg"
                // borderColor={'green'}
                >
                    <Heading size="lg" fontSize={[15, 20, 25, 30]} fontWeight='900'>Bangladesh Conflict Watch</Heading>
                </Box> */}

                <Stack
                    flexDirection={["row"]}
                    justify={["space-between"]}
                >
                    {/* App Title */}
                    <Image
                        src="/assets/BangladeshConflctWatch.png"
                        alt="Logo"
                        m={{ base: '2', md: '4' }}
                        w={['40%', "40%", '35%', '30%']}
                    />

                    {/* Login and Register Buttons */}
                    <HStack
                        justify={"center"}
                    >
                        <Button
                            size={["xs", "sm", "md", "lg"]}
                            fontWeight='bold'
                            rounded="md"
                            color='green'
                            px={[10, 12, 14, 16]}
                            py='1'
                            bg="white"
                            borderWidth={2}
                            borderRadius="md"
                            borderColor={'green'}
                            _hover={{ bg: "green", color: "white" }}
                            onClick={() => navigate("/login")}
                        >
                            Login
                        </Button>

                        <Button
                            size={["xs", "sm", "md", "lg"]}
                            fontWeight='bold'
                            rounded="md"
                            color='green'
                            px={[10, 12, 14, 16]}
                            py='1'
                            bg="white"
                            borderWidth={2}
                            borderRadius="md"
                            borderColor={'green'}
                            _hover={{ bg: "green", color: "white" }}
                            onClick={() => navigate("/register")}
                        >
                            Register
                        </Button>
                    </HStack>
                </Stack>

                <VStack spacing={[5, 5, 5, 6]} align={'left'} >
                    {/* Description Cards */}
                    <DescriptionCards />
                </VStack>

                <HStack w="100%" justify="left">
                    <Link
                        href='https://docs.google.com/forms/d/e/1FAIpQLSeAx_lDnpYMVcYxwiLqIar4_ihBafdR76YGKsNUzj4dkpc3QQ/viewform'
                        isExternal
                    // w={["35%", "45%"]}
                    >
                        <Button
                            size="lg"
                            fontWeight='bold'
                            rounded="md"
                            color='green'
                            px={[10, 12, 14, 16]}
                            py='1'
                            mt={[2, 3, 4]}
                            w="80%"
                            bg="white"
                            borderWidth={2}
                            borderRadius="md"
                            borderColor={'green'}
                            _hover={{ bg: "green", color: "white" }}
                        >
                            তথ্য/সাক্ষ্য জমা দেওয়ার বাটন
                            <ExternalLinkIcon mx='0px' ml={4} />
                        </Button>
                    </Link>
                </HStack>

                <Box>
                    <Text
                        fontSize={'12px'}
                        color='red'
                        textAlign={'center'}
                        mt={7}
                    >
                        বি. দ্র. : নোটিশ: ফর্মটিতে যেতে আপনার জিমেইলে লগইন প্রয়োজন পড়বে, কিন্তু আপনার নিরাপত্তার স্বার্থে সেই লগইন তথ্য আমরা সংরক্ষণ করছি না। তবে গুগল তার নিজের প্রয়োজনে আপনার এই ব্যক্তিগত তথ্যটি সংরক্ষণ করার অধিকার রাখে, যাতে আমাদের কোন ধরণের একসেস নেই।
                    </Text>

                    <Text
                        fontSize={'12px'}
                        color='black'
                        textAlign={'center'}
                        mt={7}
                    >
                        A documentation initiative of International Crimes Strategy Forum (ICSF) to facilitate analysis, research, justice, accountability, and reparation. The initiative is supported by other secular and human rights groups.
                    </Text>
                </Box>
            </Container >
        </Fragment>
    );
};

export default LandingPage;
