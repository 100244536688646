import './App.css';
import { RouterProvider } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { Text } from '@chakra-ui/react'
import { PersistGate } from 'redux-persist/integration/react';

import { routes } from './Routes/Routes';
import { store, persistor } from "./Redux/index";
import GlobalStyles from './GlobalStyles';

function App() {

	return (
		<Provider store={store}>
			<PersistGate loading={<Text>Loading...</Text>} persistor={persistor}>
				{/* <ChakraBaseProvider> */}
				<ChakraProvider>
					<GlobalStyles />
					<RouterProvider router={routes} />
				</ChakraProvider>
				{/* </ChakraBaseProvider> */}
			</PersistGate>
		</Provider>
	)
}

export default App;
