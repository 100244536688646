import { combineReducers } from "redux";
import {
    REQUEST_GET_LIST_TAGS,
    SUCCESS_GET_LIST_TAGS,
    FAILURE_GET_LIST_TAGS,
    SUCCESS_CREATE_TAG,
    FAILURE_CREATE_TAG,
    REQUEST_CREATE_TAG,
    SUCCESS_DELETE_TAG,
    FAILURE_DELETE_TAG,
    REQUEST_DELETE_TAG,

} from "../Constant/tags";

const initialState = {
    listTagsData: {
        listTags: [],
        error: "",
    },

    error: "",
    loading: false,

    tagsAction: {
        success: '',
        error: "",
    },
    isFetching: false,
}

const listTagsData = (state = initialState.listTagsData, action) => {
    switch (action.type) {

        case REQUEST_GET_LIST_TAGS:
            return {
                ...state,
                listTags: [],
                error: '',
            }
        case SUCCESS_GET_LIST_TAGS:
            return {
                ...state,
                listTags: action.payload ?? [],
                error: '',
            }
        case FAILURE_GET_LIST_TAGS:
            return {
                ...state,
                listTags: [],
                error: action.payload,
            }

        default:
            return state;
    }
};

const actionTags = (state = initialState.tagsAction, action) => {
    switch (action.type) {

        case REQUEST_CREATE_TAG:
        case REQUEST_DELETE_TAG:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_CREATE_TAG:
        case SUCCESS_DELETE_TAG:
            return {
                ...state,
                success: action.payload.message,
                error: '',
            }
        case FAILURE_CREATE_TAG:
        case FAILURE_DELETE_TAG:
            return {
                ...state,
                success: '',
                error: action.payload,
            }

        default:
            return state;
    }
};

const isFetching = (state = initialState.isFetching, action) => {
    switch (action.type) {
        case REQUEST_GET_LIST_TAGS:
        case REQUEST_CREATE_TAG:
        case REQUEST_DELETE_TAG:
            return true;

        case SUCCESS_GET_LIST_TAGS:
        case SUCCESS_CREATE_TAG:
        case SUCCESS_DELETE_TAG:
        case FAILURE_GET_LIST_TAGS:
        case FAILURE_CREATE_TAG:
        case FAILURE_DELETE_TAG:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    listTagsData,
    actionTags,
    isFetching,
});


export const selectListTags = state => state.listTagsData ? state.listTagsData.listTags : [];
export const selectActionTags = state => state.actionTags;

