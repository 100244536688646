import React, { useState } from 'react';
import { Box, Button, Heading, HStack, TableContainer, Text, useDisclosure, VStack } from '@chakra-ui/react';
import HistoryModal from '../HistoryModal';

const Table = ({
    currentPage,
    // setCurrentPage,
    itemsPerPage,
    // setItemsPerPage,
    tableData,
    // handlePageChange,
}) => {
    const { isOpen: isUserHistoryOpen, onOpen: onUserHistoryOpen, onClose: onUserHistoryClose } = useDisclosure();

    const [history, setHistory] = useState({});


    return (
        <Box my={4}>
            <Heading fontWeight={'bold'} as='h2' fontSize={['xl', '2xl']} textAlign={'center'} mb={5}>
                User History & Details
            </Heading>

            {/* Document list */}
            {
                tableData.length > 0 ?
                    <TableContainer >
                        <VStack mx={'auto'} minWidth={"550px"}>
                            <HStack
                                justify={'space-evenly'}
                                textAlign={'center'}
                                fontWeight={'bold'}
                                gap={6}
                                whiteSpace="break-spaces"
                            >
                                <Text w={'60px'} >No.</Text>
                                <Text w={'120px'} >Source Type</Text>
                                <Text w={'130px'} >Review Date</Text>
                                <Text w={'160px'} >Status</Text>
                                <Text w={'60px'} >Action</Text>
                            </HStack>

                            {
                                tableData &&
                                tableData.map((td, i) =>
                                    <HStack key={i}
                                        justify={'space-evenly'}
                                        textAlign={'center'}
                                        gap={6}
                                        whiteSpace="break-spaces"
                                    >
                                        <Text w={'60px'}>{((currentPage - 1) * itemsPerPage) + (i + 1)}</Text>
                                        <Text w={'120px'} >{td?.source_type}</Text>
                                        <Text w={'130px'} >{td?.review_date}</Text>
                                        <Text w={'160px'} >{td?.review_status}</Text>

                                        <Text w={'60px'} gap={1}>
                                            <Button
                                                bgColor={'gray'}
                                                p={1}
                                                px={2}
                                                mt={0}
                                                borderRadius={'lg'}
                                                color={'white'}
                                                onClick={() => {
                                                    onUserHistoryOpen();
                                                    setHistory(td);
                                                }}
                                            >
                                                Details</Button>
                                        </Text>
                                    </HStack>
                                )
                            }
                        </VStack>
                    </TableContainer>
                    :
                    <Text textAlign="center" my={4}>
                        No history found for this user.
                    </Text>
            }


            {/* Modal for editing history document */}
            <HistoryModal
                isUserHistoryOpen={isUserHistoryOpen}
                onUserHistoryClose={onUserHistoryClose}
                history={history}
            />
        </Box >
    );
};

export default Table;
