import React from 'react';
import { Text, Box, Stack } from '@chakra-ui/react'
import { 
	homeText1, 
	homeText1b, 
	homeText2, 
	homeText2b, 
	homeText3, 
	homeText3b, 
	homeText4, 
	homeText4b,
} from '../homeDynamicTexts';

const DescriptionCards = () => {
	return (
		<>
			<Stack spacing={[0]}>
				<Box
					p={[0, 1]}
					w="80%"
					display="flex"
					alignItems="left"
				>
					<Box
						width={["9px", "11px", "13px", "15px"]}
						height={["9px", "11px", "13px", "15px"]}
						bg="red.600"
						borderWidth={2}
						borderRadius="full"
						borderColor={'gray.800'}
						marginRight={3}
						my={'auto'}
					/>

					<Text>{homeText1}</Text>
				</Box>

				<Box
					p={[0, 1]}
					w="80%"
					display="flex"
					alignItems="left"
				>
					<Box
						width={["9px", "11px", "13px", "15px"]}
						height={["9px", "11px", "13px", "15px"]}
						bg="red.600"
						borderWidth={2}
						borderRadius="full"
						borderColor={'gray.800'}
						marginRight={3}
						my={'auto'}
					/>

					<Text>{homeText1b}</Text>
				</Box>
			</Stack>

			<Stack spacing={[0]}>
				<Box
					p={[0, 1]}
					w="80%"
					display="flex"
					alignItems="left"
				>
					<Box
						width={["9px", "11px", "13px", "15px"]}
						height={["9px", "11px", "13px", "15px"]}
						bg="red.600"
						borderWidth={2}
						borderRadius="full"
						borderColor={'gray.800'}
						marginRight={3}
						my={'auto'}
					/>

					<Text>{homeText2}</Text>
				</Box>

				<Box
					p={[0, 1]}
					w="80%"
					display="flex"
					alignItems="left"
				>
					<Box
						width={["9px", "11px", "13px", "15px"]}
						height={["9px", "11px", "13px", "15px"]}
						bg="red.600"
						borderWidth={2}
						borderRadius="full"
						borderColor={'gray.800'}
						marginRight={3}
						my={'auto'}
					/>

					<Text>{homeText2b}</Text>
				</Box>
			</Stack>

			<Stack spacing={[0]}>
				<Box
					p={[0, 1]}
					w="80%"
					display="flex"
					alignItems="left"
				>
					<Box
						width={["9px", "11px", "13px", "15px"]}
						height={["9px", "11px", "13px", "15px"]}
						bg="red.600"
						borderWidth={2}
						borderRadius="full"
						borderColor={'gray.800'}
						marginRight={3}
						my={'auto'}
					/>

					<Text>{homeText3}</Text>
				</Box>

				<Box
					p={[0, 1]}
					w="80%"
					display="flex"
					alignItems="left"
				>
					<Box
						width={["9px", "11px", "13px", "15px"]}
						height={["9px", "11px", "13px", "15px"]}
						bg="red.600"
						borderWidth={2}
						borderRadius="full"
						borderColor={'gray.800'}
						marginRight={3}
						my={'auto'}
					/>

					<Text>{homeText3b}</Text>
				</Box>
			</Stack>

			<Stack spacing={[0]}>
				<Box
					p={[0, 1]}
					w="80%"
					display="flex"
					alignItems="left"
				>
					<Box
						width={["9px", "11px", "13px", "15px"]}
						height={["9px", "11px", "13px", "15px"]}
						bg="red.600"
						borderWidth={2}
						borderRadius="full"
						borderColor={'gray.800'}
						marginRight={3}
						my={'auto'}
					/>

					<Text>{homeText4}</Text>
				</Box>

				<Box
					p={[0, 1]}
					w="80%"
					display="flex"
					alignItems="left"
				>
					<Box
						width={["9px", "11px", "13px", "15px"]}
						height={["9px", "11px", "13px", "15px"]}
						bg="red.600"
						borderWidth={2}
						borderRadius="full"
						borderColor={'gray.800'}
						marginRight={3}
						my={'auto'}
					/>

					<Text>{homeText4b}</Text>
				</Box>
			</Stack>
		</>
	);
};

export default DescriptionCards;