import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectExpiryTime, selectIsLoggedIn } from '../Redux/Reducer';
import { logout } from '../Redux/Thunk/Login';

const UserRoute = ({ children }) => {
    const dispatch = useDispatch();

    // const isLoggedIn = true;
    const isLoggedIn = useSelector(state => selectIsLoggedIn(state));
    const expiry_time = useSelector(state => selectExpiryTime(state));

    useEffect(() => {
        const timeOutID = setTimeout(() => {
            const curTime = new Date().getTime();
            // console.log(curTime);
            if (expiry_time == null || curTime > expiry_time) {
                dispatch(logout());
            }

        }, 2000);

        return () => { clearTimeout(timeOutID) };
    });

    if (isLoggedIn) {
        return children;
    }
    return <Navigate to='/login'></Navigate>;
};

export default UserRoute;