import { Text } from '@chakra-ui/react';

const HistoryField = ({ label, value, isEdited }) => {
    // Check if the value exists; return null if it's empty or undefined
    if (!value) return null;

    return (
        <Text wordBreak="break-word" whiteSpace="normal">
            <Text as="span" color="blue.400" fontWeight="900">
                {label}:
            </Text>
            {' '}
            {Array.isArray(value) ? value.join(', ') : value}
            {
                isEdited &&
                <> ({isEdited.name} edited on {isEdited.date})</>
            }
        </Text>
    );
};

export default HistoryField;
