import React, { useEffect, useState } from "react";
import { Box, Input, List, ListItem, Button, Tag, TagLabel, TagCloseButton, HStack, Text } from "@chakra-ui/react";

import { useSelector, useDispatch } from "react-redux";
import { selectToken, selectListEvents, selectActionEvents } from "../Redux/Reducer";
import { createEvent, deleteEvent, getListEventsData } from "../Redux/Thunk/Events";

import toast from 'react-hot-toast';
import Layout from "../Layout/Layout";

const SearchBarWithSuggestions = ({ embedded, onlySearch, deleteButton, selectedEvents, setSelectedEvents }) => {

	const { innerHeight } = window;

	const dispatch = useDispatch();

	const events = useSelector(state => selectListEvents(state));
	const token = useSelector(state => selectToken(state));
	const data = useSelector(state => selectActionEvents(state));

	// const [filteredOptions, setFilteredOptions] = useState(events);
	const [filteredOptions, setFilteredOptions] = useState([]);
	const [dataEvents, setDataEvents] = useState([]);

	useEffect(() => {
		dispatch(getListEventsData(0, 25, token));
	}, [dispatch, token]);


	// useEffect(() => {
	// 	setFilteredOptions(events);
	// }, [events]);

	const [inputValue, setInputValue] = useState("");

	const handleInputChange = (e) => {
		const value = e.target.value;
		setInputValue(value);

		if (value.length > 0) {
			const filtered = events.filter(option =>
				option.name.toLowerCase().includes(value.toLowerCase())
			);
			setFilteredOptions(filtered);
		} else {
			// setFilteredOptions(events);
			setFilteredOptions([]);
		}
	};

	const handleEventClick = (event) => {
		if (!selectedEvents.includes(event)) {
			setSelectedEvents([...selectedEvents, event]);
		}
		setInputValue("");
		// setFilteredOptions(events);
		setFilteredOptions([]);
	};

	const handleCreateEvent = (e, is_new) => {
		if (inputValue && !selectedEvents.includes(inputValue)) {
			const newEvents = [...dataEvents, inputValue];
			setDataEvents(newEvents);
			setSelectedEvents([...selectedEvents, inputValue]);
			if (is_new === true) {
				//TO-DO: need to use promise chain here to ensure consistency
				dispatch(createEvent(inputValue, token));
				dispatch(getListEventsData(0, 49, token));
			}
		}
		setInputValue("");
		// setFilteredOptions(events);
		setFilteredOptions([]);
	};

	const handleRemoveEvent = (eventToRemove) => {
		setSelectedEvents(selectedEvents.filter(event => event !== eventToRemove));
	};


	useEffect(() => {
		if (data.success) {
			toast.success(data.success);

			if (eventForDelete) {
				handleRemoveEvent(eventForDelete);
			}
		}
		if (data.error.message) {
			toast.error(data.error.message);
		}
	}, [data.error.message, data.success]);


	const [eventForDelete, setEventForDelete] = useState("");
	const handleDeleteEvent = (event) => {
		// console.log(event);

		dispatch(deleteEvent(event, token));

		setEventForDelete(event);
	};


	return (
		<>
			<Box
				maxW={{ base: "98vw", md: "80vw" }}
				px={{ base: !embedded ? 2 : 0, md: !embedded ? 4 : 0 }}
				py={{ base: !embedded ? 4 : 0, md: !embedded ? 8 : 0 }}
				// width={!embedded ? "90%" : "100%"}
				margin="0 auto"
				mt={!embedded ? 5 : 0}
				position="relative"
				minH={!embedded ? (innerHeight * 0.738) : 0}
			>
				{
					!embedded &&
					<Text
						fontWeight="semibold"
						wordBreak="break-word"
						whiteSpace="normal"
						color="gray.600"
						fontSize={["sm", 'sm', 'md']}
						mb={{ base: 1, md: 4 }}
						textAlign={'center'}
					>
						Please search for existing data using the search field. In case, you can not find your desired event, you can create one using the "Create Event" button.
					</Text>
				}

				{/* Search Bar */}
				<Box display="flex" flexDirection="row" gap={2}>
					<Input
						placeholder="Search Events..."
						value={inputValue}
						onChange={handleInputChange}
						size="md"
						focusBorderColor="#A9A9A9"
					/>

					{
						!onlySearch &&
						<Button
							bgColor={'red.600'}
							color={'white'}
							_hover={{ bg: "red.800", color: "white" }}
							p={{ base: 4, md: 4 }}
							fontSize={{ base: "sm", md: "md" }}
							borderRadius={'lg'}
							onClick={(e) => handleCreateEvent(e, filteredOptions?.length === 0)}
							type='submit'
						>
							{/* Create event */}
							{filteredOptions.length === 0 ? "Create Event" : "Add Event"}
						</Button>
					}
				</Box>

				{/* Suggestions List */}
				{filteredOptions.length > 0 && (
					<List
						position="relative"
						width="100%"
						bg="white"
						mt="2"
						borderRadius="md"
						border="1px solid #e2e8f0"
						maxHeight="150px"
						overflowY="auto"
						boxShadow="md"
						backgroundColor="#FFFF"
					>
						{filteredOptions.map((option, index) => (
							<ListItem
								key={index}
								padding="8px"
								_hover={{ backgroundColor: "#A9A9A9", cursor: "pointer" }}
								onClick={() => handleEventClick(option.name)}
							>
								{option.name}
							</ListItem>
						))}
					</List>
				)}

				{/* Selected events Display */}
				{
					!deleteButton &&
					<Box my={!embedded ? 4 : 2} display="flex" flexDirection={['column', 'row', "row"]} flexWrap="wrap" gap={2}>
						{selectedEvents.map((event, index) => (
							<Tag
								key={index}
								size="md"
								borderRadius="full"
								variant="solid"
								colorScheme="blue"
								justifyContent={"space-between"}
								px={4}
								py={1}
							// w={'100%'}
							>
								<TagLabel>{event}</TagLabel>
								<TagCloseButton onClick={() => handleRemoveEvent(event)} />
							</Tag>
						))}
					</Box>
				}

				{/* Selected events Display */}
				{
					deleteButton &&
					<Box my={!embedded ? 4 : 2} display="flex" flexDirection={['column', 'row', "row"]} flexWrap="wrap" gap={2}>
						{selectedEvents.map((event, index) => (
							<HStack border={'dotted'} p={2} borderRadius={5} borderWidth={1} justify={'space-between'} key={index}>
								<Tag
									size="md"
									borderRadius="full"
									variant="solid"
									colorScheme="blue"
									justifyContent={"space-between"}
									px={4}
									py={1}
								// w={'100%'}
								>
									<TagLabel>{event}</TagLabel>
									<TagCloseButton onClick={() => handleRemoveEvent(event)} />
								</Tag>

								<Button
									bgColor={'red.600'}
									color={'white'}
									_hover={{ bg: "red.800", color: "white" }}
									p={2}
									size={'xs'}
									borderRadius={'lg'}
									onClick={(e) => handleDeleteEvent(event)}
									type='submit'
								>
									Delete
								</Button>
							</HStack>
						))}
					</Box>
				}
			</Box >


		</>
	);
};


const Events = ({ embedded, onlySearch, deleteButton, listSelectedEvents, listSetSelectedEvents, listSearchSelectedEvents, setListSearchSelectedEvents }) => {
	// console.log("list", listSelectedEvents);
	const [selectedEvents, setSelectedEvents] = useState([]);

	return (
		<>
			{
				!embedded &&
				<Layout>
					<SearchBarWithSuggestions
						embedded={embedded}
						onlySearch={false}
						deleteButton={true}
						selectedEvents={selectedEvents}
						setSelectedEvents={setSelectedEvents}
					/>
				</Layout>
			}

			{
				embedded && !onlySearch &&
				<SearchBarWithSuggestions
					embedded={embedded}
					onlySearch={onlySearch}
					deleteButton={deleteButton}
					selectedEvents={listSelectedEvents}
					setSelectedEvents={listSetSelectedEvents}
				/>
			}

			{
				embedded && onlySearch &&
				<SearchBarWithSuggestions
					embedded={embedded}
					onlySearch={onlySearch}
					deleteButton={deleteButton}
					selectedEvents={listSearchSelectedEvents}
					setSelectedEvents={setListSearchSelectedEvents}
				/>
			}
		</>
	);
}


export default Events;