import {
    SET_UI_SCREEN
} from "../Constant/screen";
import { combineReducers } from "redux";

const initialState = {
    UIScreen: "/home",
    error: '',
}

const ui = (state = initialState, action) => {
    switch (action.type) {
        case SET_UI_SCREEN:
            return {
                ...state,
                UIScreen: action.screen,
            }
        
        default:
            return state;
    }
}

export default combineReducers({
   ui
});

export const selectCurrentScreen = state => state.ui.UIScreen;