import React from 'react';
import { Box, Text, Grid, Flex, Icon, useBreakpointValue, Stack, HStack, VStack } from '@chakra-ui/react';
import { FaSkull, FaUserInjured, FaHome, FaHospital, FaAmbulance, FaStar } from 'react-icons/fa';

const iconMapping = {
	killed: FaSkull,
	injured: FaUserInjured,
	displaced: FaHome,
	destroyedHomes: FaHome,
	healthcareFacilities: FaHospital,
	ambulances: FaAmbulance,
	// Add more mappings as needed
};

const fetchData = {
	generalInfo: {
		day: "120",
		// title: "Bangladesh Conflicts of Reform",
		dateFrom: "1 July",
		yearFrom: "2024",
		dateLast: "3 November",
		yearLast: "2024",
	},
	statistics: [
		{ type: "killed", value: "35,096", label: "Killed", details: "13,642 Children | 7,656 Women | 30,571 Civilians" },
		{ type: "injured", value: "67,240", label: "Injured" },
		{ type: "displaced", value: "2,000,000", label: "Displaced" },
		{ type: "destroyedHomes", value: "79,200", label: "Completely destroyed homes" },
		{ type: "healthcareFacilities", value: "235", label: "Healthcare facilities", details: "26 Hospitals | 63 Clinics | 146 Ambulances" }
	]
};

const StatBlock = ({ icon, value, label, details }) => {

	const formatDetails = (detailsText) => {
		return detailsText.split(/(\d+,\d+|\d+)/).map((part, index) => {
			// Check if part is a number and apply `orange.300` color
			if (/^\d/.test(part)) {
				return (
					<Text as="span" color="orange.300" key={index}>
						{part}
					</Text>
				);
			}
			// Non-numeric text remains `gray.300`
			return (
				<Text as="span" color="gray.300" key={index}>
					{part}
				</Text>
			);
		});
	};


	return (
		<Box
			textAlign="center"
			// bg="rgba(0, 0, 0, 0.7)"
			p={4}
			borderRadius="md"
			boxShadow="lg"
			// backdropFilter="blur(5px)"
			border="1px solid rgba(255, 255, 255, 0.2)"
			_hover={{ bg: "rgba(255, 255, 255, 0.2)" }}
		>
			<Stack align="center">
				<HStack spacing={4} align="flex-start">
					<Icon as={icon} boxSize={16} color="white" />

					<VStack align="flex-start" spacing={0}>
						<Text fontSize="sm" fontWeight="semibold" color="white">{label}</Text>
						<Text fontSize="2xl" fontWeight="bold" color="orange.300">{value}</Text>

						{
							details &&
							<Text fontSize="sm">
								{formatDetails(details)}
							</Text>
						}
					</VStack>
				</HStack>
			</Stack>
		</Box>
	);
};

const Dashboard = () => {
	// const columns = useBreakpointValue({ base: 1, md: 2, lg: 3 });

	const { generalInfo, statistics } = fetchData; // Access both general info and statistics from the single data file


	return (
		<Box
			// bgImage="url('/path/to/your-background-image.jpg')"
			bgImage="url('https://upload.wikimedia.org/wikipedia/commons/c/cc/7.Bangladesh_quota_reform_movement_2024.jpg')"
			bgSize="cover"
			bgRepeat="no-repeat"
			bgPosition="center"
			color="white"
			// p={8}
			minH="100vh"
		// justifyItems={'center'}
		// w="100%"
		>
			<Box
				bg="rgba(0, 0, 0, 0.7)"
				p={{ base: 6, md: 10 }}
				borderRadius="md"
				justifyItems={'center'}
				minH="100vh"
			>
				<Stack w="1200px">
					<Flex direction="row" justify="flex-end" align={'center'} mb={8} gap={4}>
						<Text fontSize={{ base: 'md', md: 'md' }} color="orange.300" fontWeight="bold">
							Logo
						</Text>

						<VStack spacing={0}>
							<Text fontSize={'sm'} fontWeight="bold" textAlign="center" >
								Human Rights
							</Text>

							<Text fontSize="sm" textAlign="center" >
								Monitor
							</Text>
						</VStack>
					</Flex>

					<Flex direction="column" alignItems="flex-start" mb={8}>
						<Text fontSize={{ base: 'xl', md: '2xl' }} color="orange.300" fontWeight="bold">
							<Text as={'span'} color="white" fontWeight="500">Days </Text>{generalInfo.day}
						</Text>

						<Text fontSize={{ base: '2xl', md: '4xl' }} fontWeight="bold" textAlign="center" color="white" mt={2}>
							<Text as={'span'} color="orange.300" fontWeight="500">Bangladesh </Text>Conflicts Watch
						</Text>

						<Text fontSize="lg" textAlign="center" mt={2}>
							{generalInfo.dateFrom}, {generalInfo.yearFrom} - {generalInfo.dateLast}, {generalInfo.yearLast}
						</Text>
					</Flex>
				</Stack>

				<Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} gap={6}>
					{statistics.map((stat, index) => (
						<StatBlock
							key={index}
							icon={iconMapping[stat.type] || FaHome} // Default icon if none provided
							value={stat.value}
							label={stat.label}
							details={stat.details}
						/>
					))}
				</Grid>

				<HStack mt={10} >
					<FaStar size={8} color="white" style={{ marginBottom: 3 }} />

					<Text fontSize={{ base: 'xs', md: 'sm' }} color="white" fontWeight="500" >
						This may not be exactly accurate
					</Text>
				</HStack>
			</Box>
		</Box>
	);
};

export default Dashboard;
