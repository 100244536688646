import React from 'react';
import { Box, Heading, Text, UnorderedList, ListItem} from '@chakra-ui/react';

export default function About(){
	return (
		<Box mt={12}>
			<Heading
				as="h2"
				fontSize="3xl"
				fontWeight="semibold"
				color="gray.800"
				mb={6}
				fontFamily="Montserrat, sans-serif"
			>
				About Our Archive
			</Heading>
			<Box bg="white" shadow="md" rounded="lg" p={6}>
				<Text textAlign='left' color="gray.600" mb={4}>
					The Bangladesh Conflict Watch Library is a comprehensive collection of resources related to conflicts and peace-building efforts in Bangladesh. Our library includes:
				</Text>
				<UnorderedList align='left' color="gray.600" spacing={2} mb={4}>
				<ListItem>Research reports and academic papers</ListItem>
				<ListItem>Policy briefs and recommendations</ListItem>
				<ListItem>News articles and media reports</ListItem>
				<ListItem>Case studies and conflict analyses</ListItem>
				<ListItem>Peace-building strategies and best practices</ListItem>
				</UnorderedList>
				<Text textAlign='left' color="gray.600">
					We aim to provide researchers, policymakers, and peace activists with valuable information to understand and address conflicts in Bangladesh. If you have a resource to contribute, please consider uploading it to our archive.
				</Text>
			</Box>
		</Box>
	);
};