import axios from "axios";
import {
    requestGetListTags,
    getListTagsSuccess,
    getListTagsFailure,
    requestCreateTag,
    createTagSuccess,
    createTagFailure,
    requestDeleteTag,
    deleteTagSuccess,
    deleteTagFailure,

    // updateSessionExpiry,
} from "../../ActionCreator/tags";

import { get_tag_list_url, tag_create_url, tag_delete_url } from "../../../allApiPath";


export const getListTagsData = (skip, limit, token) => async (dispatch, getState) => {
    dispatch(requestGetListTags())
    // console.log(token, skip, limit);

    axios.get(get_tag_list_url(skip, limit), {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(getListTagsSuccess(response.data));
            // console.log(response.data);
        }, error => {
            dispatch(getListTagsFailure(error));
        })
}


export const createTag = (
    name,
    token,
) => async (dispatch, getState) => {

    dispatch(requestCreateTag())

    axios.post(tag_create_url, {
        name: name,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(createTagSuccess(response.data));
        }, error => {
            dispatch(createTagFailure(error))
        })
}


export const deleteTag = (
    tag,
    token,
) => async (dispatch, getState) => {

    dispatch(requestDeleteTag())

    axios.post(tag_delete_url, {
        tag: tag,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then((response) => {
            dispatch(deleteTagSuccess(response.data));
        }, error => {
            dispatch(deleteTagFailure(error))
        })
}
