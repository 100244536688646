import {
    REQUEST_SUBMIT_DATA,
    SUCCESS_SUBMIT_DATA,
    FAILURE_SUBMIT_DATA,
    REQUEST_SUBMIT_ADD_LINKS_DATA,
    SUCCESS_SUBMIT_ADD_LINKS_DATA,
    FAILURE_SUBMIT_ADD_LINKS_DATA,

    // UPDATE_SESSION_EXPIRY,
} from "../Constant/home";



export const requestSubmitData = () => ({
    type: REQUEST_SUBMIT_DATA,
})
export const submitDataSuccess = (data) => ({
    type: SUCCESS_SUBMIT_DATA,
    payload: data,
})
export const submitDataFailure = (error) => ({
    type: FAILURE_SUBMIT_DATA,
    payload: error,
})

export const requestSubmitAddLinksData = () => ({
    type: REQUEST_SUBMIT_ADD_LINKS_DATA,
})
export const submitAddLinksDataSuccess = (data) => ({
    type: SUCCESS_SUBMIT_ADD_LINKS_DATA,
    payload: data,
})
export const submitAddLinksDataFailure = (error) => ({
    type: FAILURE_SUBMIT_ADD_LINKS_DATA,
    payload: error,
})

// export const updateSessionExpiry = (nextDay) => ({
//     type: UPDATE_SESSION_EXPIRY,
//     payload: nextDay,
// })
